import {
  Candidate,
  CandidateFolder,
  CandidatesOnFolderInfo,
  Folder,
  FolderStatus
} from '../../../../shared/models';
import { Params } from '@angular/router';

export class FolderCandidatesUtilities {
  constructor() {}

  static getCandidateNameByCandidates(candidates: Candidate[]): string {
    if (candidates.length !== 1) return '';

    return this.getCandidateName(candidates[0]);
  }

  static getCandidateName(candidate: Candidate): string {
    if (!candidate) return '';
    if (!candidate.fullName) return 'CANDIDATE.UNKNOWN_NAME';

    return candidate.fullName;
  }

  static getCandidateDocIds(candidates: Candidate[]): string[] {
    if (candidates?.length) {
      return candidates.map((candidate: Candidate) => candidate.docId);
    }

    return [];
  }

  static haveTheSameStatus(profiles: Candidate[]): boolean {
    if (!profiles?.length) return false;

    let firstStatus = profiles[0].currentStatusId;

    return profiles.every((profile: Candidate) => firstStatus === profile.currentStatusId);
  }

  static getFolderId(folder: Folder | CandidateFolder): string {
    return (folder as Folder)?.id || (folder as CandidateFolder)?.folderId;
  }

  static getStatusIdsFromParams(params: Params): string[] {
    return params?.statusIds && Array.isArray(params.statusIds)
      ? params?.statusIds
      : params?.statusIds
      ? [params.statusIds]
      : [];
  }

  static getHandledCandidatesOnRequest(info: CandidatesOnFolderInfo): Candidate[] {
    const { candidates, statuses = [] } = info;

    if (statuses?.length && candidates?.length) {
      candidates.forEach((candidate: Candidate, index: number) => {
        candidates[index].folderStatus = statuses.find(
          (status: FolderStatus) => status.id === candidate.currentStatusId
        );
      });
    }

    return candidates;
  }

  static getReturnProfilesOnAllStatusesFromParams(
    params: Params,
    filteredStatusIds: string[]
  ): boolean {
    return params?.returnProfilesOnAllStatuses === 'true'
      ? true
      : params?.returnProfilesOnAllStatuses === 'false'
      ? false
      : !filteredStatusIds?.length;
  }
}
