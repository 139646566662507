import { SearchCategoryTypeEnum } from '../../../../shared/enums';
import {
  FacetResponseData,
  FacetType,
  QueryLabelInfo,
  SearchHistoryQueryInfo
} from '../../../../shared/models';
import { SearchFilterInfo, SearchParamAsset } from '../../models/search-filter';
import { SearchFilterQuery } from './search-filter-query';
import { SearchFilterType } from '../../data';
import { SearchQueriesInfo } from '../../services';

export class SearchFilter {
  constructor(public data: SearchFilterInfo) {
    this.onDataSet();
  }

  // actions:

  toggleBlock(): void {
    this.toggleRevealed();
  }

  resetOtherQueries(exceptionQueryType: SearchCategoryTypeEnum): void {
    const queries: SearchFilterQuery[] = this.data.queries;

    if (queries?.length) {
      queries.forEach((queryItem: SearchFilterQuery) => {
        if (queryItem.data.searchType !== exceptionQueryType) {
          queryItem.resetInputItemsEdit();
        }
      });
    }
  }

  addExtraInputQuery(): SearchFilterQuery {
    return this.addExtraQuery(SearchFilterType.INPUT);
  }

  private addExtraQuery(filterType: SearchFilterType): SearchFilterQuery {
    let query: SearchFilterQuery;

    if (this.data?.extraQueryInfo && !this.data.extraQueryInfo.added) {
      const { matcherInfo } = this.data.extraQueryInfo;
      query = new SearchFilterQuery({
        ...this.data.extraQueryInfo,
        filterType,
        info: { searchItemList: [] },
        matcherInfo: { withMatcher: matcherInfo?.withMatcher },
        isExtraQuery: true
      });
      this.data.extraQueryInfo.added = true;

      if (!this.data.queries?.length || this.data.queries?.length === 1) {
        this.data.queries.push(query);
      } else {
        this.data.queries.splice(1, 0, query);
      }
    }

    return query;
  }

  // data handlers:

  onDataSet(): void {
    this.updateCanCollapse(true);
  }

  updateCanCollapse(handleRevealed: boolean = false): void {
    const doesHaveAnyQuery: boolean = this.doesHaveAnyQuery();
    const canCollapse: boolean = !doesHaveAnyQuery;

    if (handleRevealed && doesHaveAnyQuery && !this.data.revealed) {
      this.data.revealed = true;
    }

    this.setCanCollapse(canCollapse);
  }

  getFacetTypes(): FacetType[] {
    return this.data.queries
      .map((item: SearchFilterQuery) => item.data.facetType)
      .filter((facetType: FacetType) => !!facetType);
  }

  setFacetByResponse(facetResponse: FacetResponseData): void {
    this.data.queries.forEach((query: SearchFilterQuery) => {
      query.setFacetByResponse(facetResponse);
    });
  }

  setLabelMaps(globalLabel: string): void {
    this.data.labelMaps = this.data.queries.reduce((map, query: SearchFilterQuery) => {
      const list: QueryLabelInfo[] = query.getLabelValueList();
      const match: boolean = query.data.matcherInfo?.withMatcher
        ? query.data.matcherInfo.match
        : false;

      if (list.length) {
        const { label } = query.data;
        const resultLabel: string = label ? label : globalLabel;
        const info: SearchHistoryQueryInfo = { list, match };

        return map.set(resultLabel, info);
      }

      return map;
    }, new Map<string, SearchHistoryQueryInfo>());
  }

  isForFullPlanOnly(): boolean {
    return !!this.data?.forFullPlanOnly;
  }

  private setCanCollapse(canCollapse: boolean): void {
    this.data.canCollapse = canCollapse;
  }

  doesHaveAnyQuery(): boolean {
    if (this.data.queries?.length) {
      return this.data.queries.some((query: SearchFilterQuery) => query.doesHaveAnyValue());
    }

    return false;
  }

  private toggleRevealed(): void {
    this.data.revealed = !this.data.revealed;
  }

  // Compose queries:

  composeQueryInfo(forAPI: boolean = false, rawFilters: boolean = false): SearchQueriesInfo {
    if (this.data.queries?.length) {
      return this.composeQueryInfoByList(this.data.queries, forAPI, rawFilters);
    }

    return { queryList: [], filterList: [] };
  }

  private composeQueryInfoByList(
    queries: SearchFilterQuery[],
    forAPI: boolean = false,
    rawFilters: boolean = false
  ): SearchQueriesInfo {
    const queryParamAsset: SearchParamAsset = {};
    const filterParamAsset: SearchParamAsset = {};

    return queries.reduce(
      (info: SearchQueriesInfo, query: SearchFilterQuery) => {
        let queryString: string | string[] = query.getQueryString(forAPI, rawFilters);

        if (queryString) {
          const { queryParam, filterParam } = query.data;

          if (queryParam) {
            const { paramLastIndex, composedQueryString } = query.getQueryStringComposeInfo(
              queryString,
              queryParamAsset[queryParam]
            );
            queryParamAsset[queryParam] = paramLastIndex;

            if (Array.isArray(composedQueryString)) {
              info.queryList.push(...composedQueryString);
            } else {
              info.queryList.push(composedQueryString);
            }
          }

          if (filterParam) {
            const { paramLastIndex, composedQueryString } = query.getQueryStringComposeInfo(
              queryString,
              filterParamAsset[filterParam]
            );
            filterParamAsset[filterParam] = paramLastIndex;

            if (Array.isArray(composedQueryString)) {
              info.filterList.push(...composedQueryString);
            } else {
              info.filterList.push(composedQueryString);
            }
          }
        }

        return info;
      },
      { queryList: [], filterList: [] }
    );
  }
}
