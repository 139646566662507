import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { FolderService } from '../../../../shared/services/requests';
import {
  Folder,
  FolderActionInfo,
  FolderItemStatusesInfo,
  FolderStatus
} from '../../../../shared/models';
import { ArchiveFolderModalComponent } from '../../folder-action-modals';
import { FolderActionType } from '../../../../shared/types';
import { FolderStatusesService } from '../../../system-shared/services';
import {
  EditFolderModalComponent,
  RemoveFolderModalComponent
} from '../../../system-shared/components/modals';

@Injectable({
  providedIn: 'root'
})
export class FolderItemService {
  constructor(
    public dialog: MatDialog,
    private folderService: FolderService,
    private statusesService: FolderStatusesService
  ) {}

  private statusesInfoSubject = new BehaviorSubject<FolderItemStatusesInfo>(null);
  private filterSubject = new Subject<string[]>();

  statusesInfo$: Observable<FolderItemStatusesInfo> = this.statusesInfoSubject.asObservable();
  filter$: Observable<string[]> = this.filterSubject.asObservable();

  // Folder item methods:

  updateStatuses(statuses: FolderStatus[] = [], folderId: string): void {
    const updateInfo: FolderItemStatusesInfo = {
      statuses: [...statuses],
      folderId
    };

    this.statusesInfoSubject.next(updateInfo);
  }

  launchFilter(statusIds: string[] = null): void {
    this.filterSubject.next(statusIds);
  }

  // Folder action common methods:

  createFolder(): Observable<Folder> {
    return this.folderService.getAllStatuses().pipe(
      catchError(() => of(null)),
      mergeMap((statuses: FolderStatus[] = []) => {
        return statuses ? this.getCreatedFolderByStatuses(statuses) : null;
      })
    );
  }

  removeFolder(folder: Folder): Observable<FolderActionInfo> {
    const type: FolderActionType = 'remove';

    if (folder) {
      const matDialog: MatDialogRef<RemoveFolderModalComponent> = this.dialog.open(
        RemoveFolderModalComponent,
        {
          data: {
            name: folder.name,
            candidateCount: folder.candidatesCount
          }
        }
      );

      return matDialog
        .afterClosed()
        .pipe(
          mergeMap((condition: boolean) =>
            this.getFolderActionInfoForRemove(folder, type, condition)
          )
        );
    }

    return of(null);
  }

  editFolder(folder: Folder, type: FolderActionType): Observable<FolderActionInfo> {
    if (type == 'edit') {
      return this.handleEditFolder(folder);
    } else if (type === 'archive' || type === 'unarchive') {
      return this.handleArchiveFolder(folder, type);
    }
  }

  private handleEditFolder(folder: Folder): Observable<FolderActionInfo> {
    if (folder) {
      return this.folderService.getAllStatuses().pipe(
        mergeMap((statuses: FolderStatus[] = []) => {
          this.statusesService.handleStatuses(statuses);

          const matDialog: MatDialogRef<EditFolderModalComponent> = this.getMatDialogForEdit(
            folder,
            statuses
          );

          return matDialog
            .afterClosed()
            .pipe(
              mergeMap((data: Folder) => this.getFolderActionInfoForEdit(folder, 'edit', data))
            );
        })
      );
    }

    return of(null);
  }

  private handleArchiveFolder(
    folder: Folder,
    type: FolderActionType
  ): Observable<FolderActionInfo> {
    if (folder) {
      const matDialog: MatDialogRef<ArchiveFolderModalComponent> = this.getMatDialogForArchive(
        folder.name,
        type
      );

      return matDialog
        .afterClosed()
        .pipe(mergeMap((data: boolean) => this.getFolderActionInfoForEdit(folder, type, data)));
    }

    return of(null);
  }

  private getCreatedFolderByStatuses(statuses: FolderStatus[] = []): Observable<Folder> {
    const matDialog: MatDialogRef<EditFolderModalComponent> = this.dialog.open(
      EditFolderModalComponent,
      {
        data: {
          statuses,
          type: 'create'
        }
      }
    );

    return matDialog.afterClosed().pipe(
      mergeMap((folder: Folder) => {
        if (folder) {
          return this.folderService.addFolder(folder);
        }

        return of(null);
      })
    );
  }

  private getFolderActionInfoForRemove(
    folder: Folder,
    type: FolderActionType,
    condition: boolean
  ): Observable<FolderActionInfo> {
    if (condition) {
      return this.folderService.deleteFolder(folder.id).pipe(
        map((folders: Folder[]) => {
          return {
            folder,
            folders,
            type
          };
        })
      );
    }

    return of(null);
  }

  private getFolderActionInfoForEdit(
    folder: Folder,
    type: FolderActionType,
    data: Folder | boolean
  ): Observable<FolderActionInfo> {
    if (data) {
      const updatedFolder: Folder =
        type === 'edit'
          ? (data as Folder)
          : FolderItemService.getEditedWithArchiveFolder(folder, type);

      return this.folderService.editFolder(updatedFolder).pipe(
        map((folder: Folder) => {
          return {
            folder,
            type
          };
        })
      );
    }

    return of(null);
  }

  private getMatDialogForEdit(
    folder: Folder,
    statuses: FolderStatus[] = []
  ): MatDialogRef<EditFolderModalComponent> {
    return this.dialog.open(EditFolderModalComponent, {
      data: {
        folder,
        statuses,
        type: 'edit'
      }
    });
  }

  private getMatDialogForArchive(
    name: string,
    type: FolderActionType
  ): MatDialogRef<ArchiveFolderModalComponent> {
    return this.dialog.open(ArchiveFolderModalComponent, {
      data: {
        name,
        toArchive: type === 'archive'
      }
    });
  }

  private static getEditedWithArchiveFolder(folder: Folder, type: FolderActionType): Folder {
    let updatedFolder: Folder = null;

    if (type === 'archive' || type === 'unarchive') {
      updatedFolder = {
        ...folder,
        archived: !folder.archived
      };
    }

    return updatedFolder;
  }
}
