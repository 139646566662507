import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services';
import { invitePageRegExp } from '../utilities';
import { SystemPages } from '../enums/routerPaths.enum';

@Injectable({
  providedIn: 'root'
})
export class PublicGuard {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let canActive: boolean = true;
    const isUserLoggedIn: boolean = this.authService.getIsUserLoggedIn();

    if (isUserLoggedIn) {
      if (PublicGuard.isResetPasswordLink(route)) {
        this.router.navigate([SystemPages.USER_PROFILE], { queryParams: route.queryParams });
        return false;
      }

      canActive = PublicGuard.isAvailableToAll(state);

      if (!canActive) {
        this.router.navigateByUrl(SystemPages.SEARCH).then(() => {});
      }
    }

    return canActive;
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(next, state);
  }

  private static isAvailableToAll(state: RouterStateSnapshot): boolean {
    const url: string = state.url;

    return this.isInvitePage(url);
  }

  private static isInvitePage(url: string): boolean {
    return !!url.match(invitePageRegExp);
  }

  private static isResetPasswordLink(route: ActivatedRouteSnapshot): boolean {
    return route.queryParamMap.has('password_key');
  }
}
