<app-modal
  [info]="info"
  (closeModal)="closeModal()"
  (onSubmit)="onSubmit()">
  <ng-template #textContent>
    <p class="modal__text">
      <ng-container *ngIf="data.type === 'move'">
        {{ 'FOLDERS.FOLDER_PAGE.CANDIDATE_ACTIONS.MODALS.MOVE.MOVE' | translate }}

        <ng-container [ngTemplateOutlet]="candidateInfo"></ng-container>
      </ng-container>

      <ng-container *ngIf="data.type === 'duplicate'">
        {{ 'FOLDERS.FOLDER_PAGE.CANDIDATE_ACTIONS.MODALS.MOVE.DUPLICATE' | translate }}

        <ng-container [ngTemplateOutlet]="candidateInfo"></ng-container>
      </ng-container>
    </p>
  </ng-template>

  <ng-template #scrollableContent>
    <div class="folders">
      <app-folder-card-small
        class="folders__item"
        *ngFor="let folder of form.value"
        [type]="'simple'"
        [folder]="folder"
        [candidateFullName]="data?.candidateName"
        (clickFolder)="choseFolder(folder)">
      </app-folder-card-small>
    </div>
  </ng-template>
</app-modal>

<ng-template #candidateInfo>
  <ng-container *ngIf="data?.candidateName">
    {{ data.candidateName | translate }}
  </ng-container>

  <ng-container *ngIf="data?.candidateLength > 1">
    {{ data.candidateLength }}
    {{ data.candidateLength | currentNumeric: 'PROFILE' | translate }}
  </ng-container>
</ng-template>
