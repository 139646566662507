<div class="flex-column" [ngClass]="{ 'with-picture': type !== 'forCandidatePage' }">
  <span class="folder-name">
    {{ folder.name }}
  </span>

  <span class="folder-candidate-numbers" [ngClass]="{ small: type !== 'forCandidatePage' }">
    <ng-container *ngIf="folder.candidatesCount; else zeroCandidates">
      {{ folder.candidatesCount }}
    </ng-container>

    <ng-template #zeroCandidates>
       0 
    </ng-template>

    {{ folder.candidatesCount | currentNumeric: 'PROFILE' | translate }}
  </span>
</div>
