import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-page-select-dropdown',
  templateUrl: './page-select-dropdown.component.html',
  styleUrls: ['./page-select-dropdown.component.scss']
})
export class PageSelectDropdownComponent {
  pages: number[] = [];

  @Input() activePage = 1;
  @Input('pages') set setPages(pages: number) {
    const showPages = Math.min(pages, 100);
    this.pages = Array.from(Array(showPages)).map((val, index) => index + 1);
  }

  @Output() pageSelected = new EventEmitter<number>();

  selectPage(pageNumber: number): void {
    if (pageNumber !== this.activePage) {
      this.pageSelected.emit(pageNumber);
    }
  }
}
