import { FolderStatusColorType, StatusColorByTypeInfo } from '../../../../shared/types';
import { FolderStatus } from '../../../../shared/models';

interface SystemStatusesNameInfo {
  [key: string]: string;
}

export class FolderStatusesUtilities {
  private static readonly statusColorByTypeInfo: StatusColorByTypeInfo = {
    'anger-light': '#FF94AF',
    anger: '#E05679',
    'anger-dark': '#BF264C',
    'beaks-light': '#FFE187',
    beaks: '#F2C94C',
    'beaks-dark': '#CCA525',
    'wings-light': '#41BFA4',
    wings: '#07B38D',
    'wings-dark': '#057F65',
    'calm-light': '#80B9FB',
    calm: '#3592FA',
    'calm-dark': '#2A73C7',
    'feathers-light': '#8591FF',
    'feathers-medium': '#717BDA',
    feathers: '#2F3AA6'
  };

  private static readonly systemStatusesNameInfo: SystemStatusesNameInfo = {
    not_interested: 'FOLDERS.STATUSES.SYSTEM_NAMES.NOT_INTERESTED',
    interested: 'FOLDERS.STATUSES.SYSTEM_NAMES.INTERESTED',
    contacted: 'FOLDERS.STATUSES.SYSTEM_NAMES.CONTACTED',
    selected: 'FOLDERS.STATUSES.SYSTEM_NAMES.SELECTED'
  };

  static getStatusColorByTypeInfo(): StatusColorByTypeInfo {
    return FolderStatusesUtilities.statusColorByTypeInfo;
  }

  static getStatusNameForTranslate(status: FolderStatus): string {
    return FolderStatusesUtilities.systemStatusesNameInfo[status?.name] || status?.name;
  }

  static getStatusColorValue(colorName: string): string {
    if (!colorName) {
      return '';
    }

    return FolderStatusesUtilities.statusColorByTypeInfo[colorName];
  }

  static getFolderStatusColorTypeFilteredList(): FolderStatusColorType[] {
    return Object.keys(FolderStatusesUtilities.statusColorByTypeInfo).map(
      (color: FolderStatusColorType) => color
    );
  }

  static getFilteredColorsByExceptionList(
    exceptionList: FolderStatusColorType[]
  ): FolderStatusColorType[] {
    let filteredList: FolderStatusColorType[] = [];

    if (exceptionList?.length) {
      Object.keys(FolderStatusesUtilities.statusColorByTypeInfo).forEach(
        (color: FolderStatusColorType) => {
          if (!exceptionList.includes(color)) {
            filteredList.push(color);
          }
        }
      );
    }

    if (!filteredList?.length) {
      filteredList = FolderStatusesUtilities.getFolderStatusColorTypeFilteredList();
    }

    return filteredList;
  }

  static getRandomColorByExceptionList(
    exceptionList: FolderStatusColorType[]
  ): FolderStatusColorType {
    let filteredList: FolderStatusColorType[];

    if (exceptionList?.length) {
      filteredList = FolderStatusesUtilities.getFilteredColorsByExceptionList(exceptionList);
    } else {
      filteredList = FolderStatusesUtilities.getFolderStatusColorTypeFilteredList();
    }

    const randomIndex: number = Math.floor(Math.random() * filteredList.length);

    return filteredList[randomIndex];
  }
}
