import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { RouterEvent } from '@angular/router';
import { filter, map, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import {
  AccountService,
  AuthService,
  LocalizationService,
  RoutesHandlerService,
  ScrollHandlerService,
  StateService,
  UserService
} from './shared/services';

import { Account, ConditionInfo, HeaderState, Locale, User } from './shared/models';
import { CodesVariableNamesEnum } from './shared/enums';
import { systemLocales } from './shared/config';
import { ScrollbarComponent } from './scrollbar/scrollbar.component';
import { AppHandlerService } from './modules/core/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private handler: AppHandlerService,
    private userService: UserService,
    private accountService: AccountService,
    private authService: AuthService,
    private routesHandler: RoutesHandlerService,
    private headerService: StateService,
    private localization: LocalizationService,
    private scrollHandler: ScrollHandlerService
  ) {}

  @ViewChild('scrollbar') scrollbarRef: ScrollbarComponent;

  windowWidth: number;
  isUserLoggedIn: boolean;
  isMainLanding: boolean;

  private user: User;
  private headerState: HeaderState;
  private accountInfo: Account;
  private currentUserInit: User;
  private updateUserSet = false;
  private isUpdateCurrentUserStarted = false;

  private disableScrollBar: boolean = false;

  private unsubscribe$ = new Subject<void>();
  private updateIsRoutesDataInit$ = new BehaviorSubject<boolean>(false);
  private disableScrollBarSubject = new BehaviorSubject<ConditionInfo>({
    condition: this.disableScrollBar
  });

  isRotesDataInit$: Observable<boolean> = this.updateIsRoutesDataInit$.asObservable();
  systemLocale$: Observable<Locale>;
  disableScrollBar$: Observable<ConditionInfo> = this.disableScrollBarSubject.asObservable();

  get isItLanding(): boolean {
    return this.headerState?.landing || this.headerState?.auth || !this.isUserLoggedIn;
  }

  @HostListener('window:resize') onResize(): void {
    this.windowWidth = this.handler.getWindowWidth();
  }

  @HostListener('window:beforeunload', ['$event'])
  // @ts-ignore
  async ngOnDestroy($event) {
    $event.preventDefault();
    this.resetData();

    this.authService.handleRefreshingOnDestroy();

    this.handler.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  @HostListener('window:storage', ['$event']) onStorage(event: StorageEvent) {
    if (event?.key === CodesVariableNamesEnum.accessToken) {
      this.authService.handleHeldRequests();
    }
  }

  ngOnInit(): void {
    this.handler.init();
    this.initWidowWidth();
    this.initUser();
    this.handleRoutesData();
    this.initSubscriptions();
  }

  ngAfterViewInit(): void {
    this.handler.setScrollbarRef(this.scrollbarRef?.element?.nativeElement);
  }

  private initWidowWidth(): void {
    this.windowWidth = this.handler.getWindowWidth();
  }

  private initUser(): void {
    const userId: string = this.authService.getUserId();

    this.localization.setSystemLocale(null, false);
    this.userService.updateCurrentUser(userId);

    this.isUpdateCurrentUserStarted = true;
    this.currentUserInit = null;
  }

  private initSubscriptions(): void {
    this.handleUpdateAccount();
    this.handleLogout();

    this.setSystemLocalePipe();
    this.handleUpdateState();
    this.onUpdateDisableScrollBar();
  }

  private onUpdateDisableScrollBar(): void {
    this.scrollHandler.disableScrollBar$
      .pipe(
        filter((info: ConditionInfo) => !!info),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((info: ConditionInfo) => {
        this.updateDisableScrollBar(info.condition);
      });
  }

  private setSystemLocalePipe(): void {
    this.systemLocale$ = this.localization.systemLocale$.pipe(map((locale: Locale) => locale));
  }

  private updateDisableScrollBar(disable: boolean = false): void {
    if (disable !== this.disableScrollBar) {
      this.disableScrollBar = disable;

      this.disableScrollBarSubject.next({ condition: this.disableScrollBar });
    }
  }

  private handleUpdateState(): void {
    this.headerService.updateState$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state: HeaderState) => {
        this.headerState = state;
      });
  }

  private handleCurrentUser(isUpdatingAccount: boolean): void {
    this.userService.currentUser$
      .pipe(
        filter((user: User) => !!user),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((updatedUser: User) => {
        this.user = updatedUser;

        if (
          !this.currentUserInit ||
          this.currentUserInit?.userId !== updatedUser?.userId ||
          isUpdatingAccount
        ) {
          this.currentUserInit = updatedUser;

          this.handleAccountAfterGettingUser();
        }

        this.userService.setUser(this.user);
        this.userService.updateUserLocale(updatedUser);

        this.setPagesTitles();
      });
  }

  private handleRoutesData(): void {
    this.routesHandler
      .getRouterEventsAtTheEnd(true)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((event: RouterEvent) => {
        this.handleRoutesDataEndSuccess(event);
      });
  }

  private handleRoutesDataEndSuccess(event: RouterEvent): void {
    this.routesHandler.handleRouteLangId(event, this.authService.getIsUserLoggedIn());
    this.preHandleRoutesDataEndSuccess(event);
    this.handleDisablingScrollForPages(event);

    if (
      !this.isUserLoggedIn &&
      (this.routesHandler.getIsAuthPage(null, event) ||
        this.routesHandler.getIsLanding(null, event))
    ) {
      this.handler.clearWidget();
      this.userService.updateUserLocale();
    } else if (systemLocales.some((locale: Locale) => event?.url?.includes(locale))) {
      this.handleSystemOnRoutesChange(event);
    }

    this.setPagesTitles();
  }

  private handleDisablingScrollForPages(event: RouterEvent): void {
    const isSearchPage: boolean = this.routesHandler.getIsSearchState(null, event);
    const isProfilePage: boolean = this.routesHandler.getIsCandidateState(null, event);
    const isAdminPanelPage: boolean = this.routesHandler.getIsAdminPanelState(null, event);
    const isUserState: boolean = this.routesHandler.getIsUserState(null, event);

    this.updateDisableScrollBar(isSearchPage || isProfilePage || isUserState || isAdminPanelPage);
  }

  private preHandleRoutesDataEndSuccess(event: RouterEvent): void {
    this.isMainLanding = this.routesHandler.getIsMainLanding(null, event);
    this.isUserLoggedIn = this.authService.getIsUserLoggedIn();
    const isRotesDataInit = true;

    this.handleState(event);
    this.updateIsRoutesDataInit$.next(isRotesDataInit);
  }

  private handleSystemOnRoutesChange(event: RouterEvent): void {
    const accountCondition: boolean = this.routesHandler.getIsSystemOnRoutesChangeByEvent(event);
    const updateUserCondition: boolean =
      this.routesHandler.getUserChangeOnRoutesChangeByEvent(event);

    this.handler.handleChangedUser(this.user);

    if (accountCondition) {
      this.setAccountInfo();
    }

    if (!this.isUpdateCurrentUserStarted && updateUserCondition) {
      const userId: string = this.authService.getUserId();
      this.isUpdateCurrentUserStarted = true;

      this.userService.updateCurrentUser(userId);
    }
  }

  private setPagesTitles(): void {
    this.routesHandler
      .getPagesTitles()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resultTitle: string) => {
        this.routesHandler.setTitle(resultTitle);
      });
  }

  private handleState(event: RouterEvent): void {
    const state: string = this.routesHandler.getStateByEvent(event);

    this.headerService.updateState(state);
  }

  private setAccountInfo(isUpdatingAccount: boolean = false, user: User = null): void {
    this.handler
      .getAccount()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((account: Account) => {
        this.handleGettingAccountSuccess(account, isUpdatingAccount, user);
      });
  }

  private handleGettingAccountSuccess(
    account: Account,
    isUpdatingAccount: boolean = false,
    user: User = null
  ): void {
    if (account) {
      this.accountInfo = account;

      if (!this.currentUserInit) {
        this.handleInitUserOnAccount(isUpdatingAccount);
      } else {
        this.accountService.updateAccountInfo(this.accountInfo);
      }

      if (user) {
        this.userService.setUserInfoAndUpdate(user);
      }
    }
  }

  private handleInitUserOnAccount(isUpdatingAccount: boolean): void {
    this.handleCurrentUser(isUpdatingAccount);

    if (!this.isUpdateCurrentUserStarted && !this.user && !this.updateUserSet) {
      const userId: string = this.authService.getUserId();
      this.updateUserSet = true;
      this.isUpdateCurrentUserStarted = true;

      this.userService.updateCurrentUser(userId);
    }
  }

  private handleAccountAfterGettingUser(): void {
    if (this.accountInfo) {
      this.accountService.updateAccountInfo(this.accountInfo);
    }
  }

  private handleUpdateAccount(): void {
    this.accountService.updateAccount$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(({ withMarkers, user }) => {
        this.setAccountInfo(true, user);
      });
  }

  private handleLogout(): void {
    this.authService.onLogout$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      if (this.accountService.getIsAccountBlocked(this.accountInfo)) {
        this.handler.closeAllDialogs();
      }

      this.handler.clearWidget();
      this.resetData();
    });
  }

  private resetData() {
    this.isUserLoggedIn = false;
    this.headerState = null;
    this.accountInfo = null;
    this.user = null;
    this.currentUserInit = null;
  }
}
