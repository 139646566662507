import { Injectable } from '@angular/core';
import { NavigationExtras, Params, Router } from '@angular/router';
import { catchError, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';

import {
  FolderAccessType,
  FolderActionType,
  FolderStateType,
  FolderStatusColorType,
  StatusColorByTypeInfo,
  StatusesInfoFilterKey
} from '../../../../shared/types';
import {
  AddProfileInFoldersRequestData,
  Candidate,
  CandidateFolder,
  CandidateFoldersInfo,
  CandidatesMoveToFolderActionData,
  CandidateToFolderHandlingData,
  ConditionInfo,
  FilterFoldersData,
  FilterFoldersFlags,
  Folder,
  FolderFilter,
  FolderFormValue,
  FolderMassActionDto,
  FolderNavigateState,
  FolderParams,
  FoldersPageState,
  FoldersStatusListInfo,
  FolderStatus,
  FolderStatusColorInfo,
  GetAllFoldersInfo,
  OpenFolderInfo,
  UpdateActiveFolderInfo
} from '../../../../shared/models';
import { FolderService } from '../../../../shared/services/requests';
import { FolderPage } from '../../../../shared/enums';
import { LocationService, RoutesHandlerService } from '../../../../shared/services';
import { FolderItemService } from '../../../folders/shared/services';
import { FolderHandlerUtilities } from './folder-handler.utilities';
import { FoldersNotificationsService } from './folders-notifications.service';
import { FolderStatusesService } from './folder-statuses.service';

@Injectable({
  providedIn: 'root'
})
export class FolderHandlerService {
  constructor(
    private folderService: FolderService,
    private statusesService: FolderStatusesService,
    private notificationService: FoldersNotificationsService,
    private router: Router,
    private routesHandler: RoutesHandlerService,
    private folderItemService: FolderItemService,
    private locationService: LocationService
  ) {}

  private readonly archiveTypes: FolderActionType[] = ['archive', 'unarchive'];

  private folders: Folder[] = [];
  private foldersReceived = false;
  private filteredFolders: Folder[] = [];
  private folderFilterInfo: FolderFilter;
  private loading: boolean = false;
  private activeFolderId: string;
  private state: FoldersPageState = FoldersPageState.FOLDER;

  private onOpenFolderPage$ = new Subject<OpenFolderInfo>();
  private foldersSubject = new BehaviorSubject<Folder[]>([]);
  private folderFilterInfoSubject = new BehaviorSubject(null);
  private updateLoadingInfo$ = new BehaviorSubject<ConditionInfo>({
    condition: false
  });
  private launchUpdateActiveFolderSubject = new BehaviorSubject<UpdateActiveFolderInfo>({
    update: false
  });
  private updateState$ = new BehaviorSubject<FoldersPageState>(this.state);

  openFolderPage$: Observable<OpenFolderInfo> = this.onOpenFolderPage$.asObservable();
  folders$: Observable<Folder[]> = this.foldersSubject.asObservable();
  folderFilterInfo$: Observable<FolderFilter> = this.folderFilterInfoSubject.asObservable();
  loadingInfo$: Observable<ConditionInfo> = this.updateLoadingInfo$.asObservable();
  onUpdateActiveFolder$: Observable<UpdateActiveFolderInfo> =
    this.launchUpdateActiveFolderSubject.asObservable();
  state$: Observable<FoldersPageState> = this.updateState$.asObservable();

  get areFoldersGet(): boolean {
    return !!this.folders?.length || this.foldersReceived;
  }

  get firstFilteredFolder(): Folder {
    if (this.filteredFolders?.length) {
      return this.filteredFolders[0];
    }
    return null;
  }

  // statuses:

  getSortedByOrderStatusesList(statuses: FolderStatus[] = []): FolderStatus[] {
    return statuses.sort((a: FolderStatus, b: FolderStatus) => {
      return a.position - b.position;
    });
  }

  getStatusColorsList(activeColor: string): FolderStatusColorInfo[] {
    const statusColorByTypeInfo: StatusColorByTypeInfo =
      this.statusesService.getStatusColorByTypeInfo();

    return Object.keys(statusColorByTypeInfo).map((color: FolderStatusColorType) => {
      const active: boolean = color === activeColor;

      return { type: color, active };
    });
  }

  launchUpdateActiveFolder(folder: Folder = null): void {
    this.launchUpdateActiveFolderSubject.next({ update: true, folder });
  }

  getStatusesListInfo(
    statuses: FolderStatus[],
    key: StatusesInfoFilterKey = 'defaultStatus'
  ): FoldersStatusListInfo {
    const { filteredStatuses, restStatuses } = FolderHandlerUtilities.getNotSortedStatusesListInfo(
      statuses,
      key
    );

    const sortedDefaultStatuses: FolderStatus[] =
      this.getSortedByOrderStatusesList(filteredStatuses);
    const sortedRestStatuses: FolderStatus[] = this.getSortedByOrderStatusesList(restStatuses);

    return {
      filteredStatuses: sortedDefaultStatuses,
      restStatuses: sortedRestStatuses
    };
  }

  // state handlers:

  setFolderState(): void {
    this.updateState(FoldersPageState.FOLDER);
  }

  setFoldersState(): void {
    this.updateState(FoldersPageState.FOLDERS);
  }

  private updateState(state: FoldersPageState): void {
    if (state !== this.state) {
      this.state = state;

      this.updateState$.next(state);
    }
  }

  // loading:

  startLoading(): void {
    if (!this.loading) {
      this.updateLoadingInfo(true);
    }
  }

  finishLoading(): void {
    if (this.loading) {
      this.updateLoadingInfo(false);
    }
  }

  private updateLoadingInfo(loading: boolean): void {
    this.loading = loading;

    this.updateLoadingInfo$.next({ condition: loading });
  }

  // else handlers:

  private setFoldersReceived(): void {
    if (!this.foldersReceived) {
      this.foldersReceived = true;
    }
  }

  // Folder:

  dispatchGetAllFolders(info: GetAllFoldersInfo = {}): Observable<null> {
    return this.folderService.getAllFolders().pipe(
      catchError(() => of(null)),
      switchMap((folders: Folder[]) => {
        const { activeFolder, type, activeFolderId } = info;

        if (!folders || !activeFolder) {
          this.updateFolders(folders);
          return of(null);
        }

        this.setFoldersReceived();
        this.preHandleFoldersAfterGet(folders, info);

        const isArchiveType: boolean = this.isArchiveType(type);
        const filter: boolean = this.isActiveFolder(activeFolderId);

        return this.filterFoldersOnFolder({
          folder: activeFolder,
          folders,
          filter,
          isArchiveType
        });
      })
    );
  }

  generateNewFolderFromFormValue(value: FolderFormValue, folderForEdit: Folder = {}): Folder {
    const { name, description } = value;
    const privateAccess: boolean = value.accessType === 'private';
    const statusIds: string[] = FolderHandlerUtilities.getDefaultStatusIds(
      value.selectedStatuses,
      !!folderForEdit?.name
    );

    if (!folderForEdit) {
      folderForEdit = {};
    }

    return {
      ...folderForEdit,
      statuses: null,
      name,
      description,
      privateAccess,
      statusIds
    };
  }

  getFolderArchiveActionType(toArchive: boolean = true): FolderActionType {
    return FolderHandlerUtilities.getFolderArchiveActionType(toArchive);
  }

  navigateToFoldersPage(): void {
    const firstFolder: Folder = this.filteredFolders?.length
      ? this.filteredFolders[0]
      : this.folders?.length
      ? this.folders[0]
      : null;

    if (firstFolder && !firstFolder.archived) {
      this.openFolderPage(firstFolder);
    } else {
      this.router.navigate([FolderPage.URL]).then(() => {});
    }
  }

  private isActiveFolder(folderId: string): boolean {
    return folderId === this.activeFolderId;
  }

  private isArchiveType(type: FolderActionType): boolean {
    return this.archiveTypes.includes(type);
  }

  setActiveFolderId(activeFolderId: string): void {
    this.activeFolderId = activeFolderId;

    if (this.filteredFolders?.length) {
      this.filteredFolders.forEach((item: Folder, index: number) => {
        this.filteredFolders[index].active = item.id === activeFolderId && !item.archived;
      });
    }
  }

  // filters folders:

  filterFoldersOnFolder(data: FilterFoldersData): Observable<null> {
    const { folders, filter = false } = data;

    this.startLoading();

    this.folderFilterInfo = this.getFilterInfoOnFilter(data);

    return this.filterFolders({ info: this.folderFilterInfo, folders, filter });
  }

  private getFilterInfoOnFilter(info: FilterFoldersData): FolderFilter {
    const { folder } = info;

    if (folder) {
      return this.getNewFilterInfo(folder);
    }

    return FolderHandlerUtilities.getDefaultFolderFilterInfo();
  }

  private filterFolders({
    info,
    folders = null,
    filter = false,
    getAllFolders = false
  }: FilterFoldersFlags): Observable<null> {
    if (!info?.state || !info.access) {
      return of(null);
    }

    this.setFolderFilterInfo(info);

    if (getAllFolders || (!folders && !this.foldersReceived)) {
      return this.folderService.getAllFolders().pipe(
        catchError(() => of(null)),
        map((folders: Folder[]) => this.getFilterFoldersOnGetAll(folders, filter))
      );
    } else {
      this.filterFoldersOnPrepare(folders, filter);

      return of(null);
    }
  }

  updateFilter(filter: FolderFilter): Observable<null> {
    const oldFilter = this.folderFilterInfo;

    this.startLoading();
    this.setFolderFilterInfo(filter);

    return this.folderService.getAllFolders().pipe(
      tap((folders: Folder[]) => {
        this.setFoldersReceived();
        this.setActiveFieldOnFolders(folders);
        this.updateFolders(folders);

        const firstFolder: Folder = this.filteredFolders?.length ? this.filteredFolders[0] : null;
        if (firstFolder && !firstFolder.archived) {
          this.openFolderPage(firstFolder);

          return;
        }

        // Archive state or empty filteredFolders
        this.setFoldersState();
        this.setActiveFolderId(null);
        this.setPreviewLocation();
        this.finishLoading();
      }),
      map(() => null),
      catchError(() => {
        this.setFolderFilterInfo(oldFilter);
        this.finishLoading();

        return of(null);
      })
    );
  }

  updateListByFolder(folder: Folder): void {
    if (folder && this.filteredFolders?.length) {
      this.filteredFolders.find((item: Folder, index: number) => {
        const isActiveFolder: boolean = item.id === folder.id;

        if (isActiveFolder) {
          this.filteredFolders[index].candidatesCount = folder.candidatesCount;
          this.filteredFolders[index].candidateAdded = folder.candidateAdded;
        }

        return isActiveFolder;
      });
    }
  }

  private setFilterInfoByTypes(state: FolderStateType, access: FolderAccessType): void {
    const info: FolderFilter = {
      state: {
        opened: state === 'opened',
        archived: state === 'archived'
      },
      access: {
        private: access === 'private',
        team: access === 'team'
      }
    };

    this.setFolderFilterInfo(info);
  }

  private getFilterFoldersOnGetAll(folders: Folder[], filter: boolean): null {
    this.setFoldersReceived();

    if (folders) {
      this.preHandleFoldersAfterGet(folders, { type: 'init' });
      this.filterFoldersOnPrepare(folders, filter);
    }

    return null;
  }

  private setFolderFilterInfo(formInfo: FolderFilter = null): void {
    if (!formInfo) {
      formInfo = FolderHandlerUtilities.getDefaultFolderFilterInfo();
    }

    this.folderFilterInfo = formInfo;

    this.folderFilterInfoSubject.next(this.folderFilterInfo);
  }

  private getNewFilterInfo(folder: Folder): FolderFilter {
    const folderArchived: boolean = folder.archived;
    const isFolderPrivate: boolean = folder.privateAccess;

    if (folder) {
      return {
        state: {
          opened: !folderArchived,
          archived: folderArchived
        },
        access: {
          private: isFolderPrivate,
          team: !isFolderPrivate
        }
      };
    }

    return FolderHandlerUtilities.getDefaultFolderFilterInfo();
  }

  private filterFoldersOnPrepare(folders: Folder[], filter: boolean): void {
    this.updateFolders(folders);

    if (filter) {
      const firstFolder: Folder = this.filteredFolders?.length ? this.filteredFolders[0] : null;

      if (firstFolder && !firstFolder.archived) {
        this.openFolderPage(firstFolder);
      } else if (firstFolder?.archived || this.isPreviewPermitted()) {
        this.previewFolders();
      } else if (this.areFoldersGet) {
        this.navigateToFoldersPage();
      } else {
        this.finishLoading();
      }
    }
  }

  private isPreviewPermitted(): boolean {
    return (
      this.folderFilterInfo.state.archived ||
      (!this.filteredFolders?.length && this.foldersReceived)
    );
  }

  private getFilteredFolders(folders: Folder[] = null): Folder[] {
    if (!folders) {
      folders = this.folders;
    }

    if (!this.folderFilterInfo) {
      this.setFolderFilterInfo();
    }

    return folders?.slice()?.filter((folder: Folder, index: number) => {
      const condition: boolean = FolderHandlerUtilities.getFilteredFoldersResult(
        folder,
        this.folderFilterInfo
      );

      if (condition) {
        folders[index].active = this.activeFolderId
          ? folder.id === this.activeFolderId && !folder.archived
          : false;
      }

      return condition;
    });
  }

  // rest handlers:
  handleAfterRemove(folders: Folder[]): void {
    this.updateFolders(folders);
    const activeWasDeleted = !folders.find((folder: Folder) => folder.active);
    if (activeWasDeleted) {
      this.navigateToFoldersPage();
    }
  }

  handleAfterCreate(folders: Folder[], newFolder: Folder): void {
    this.updateFolders(folders);
    this.openFolderPage(newFolder);
  }

  handleAfterEdit(folders: Folder[], info: GetAllFoldersInfo): void {
    const { activeFolder, type, activeFolderId } = info;

    if (activeFolderId === this.activeFolderId) {
      this.launchUpdateActiveFolder(activeFolder);
      const isArchiveType: boolean = this.isArchiveType(type);
      const filter: boolean = this.isActiveFolder(activeFolderId);

      this.folderFilterInfo = this.getFilterInfoOnFilter({
        folder: activeFolder,
        folders,
        filter,
        isArchiveType
      });
      this.setFolderFilterInfo(this.folderFilterInfo);
      this.updateFolders(folders);
    } else {
      this.updateFolders(folders);
      this.openFolderPage(activeFolder);
    }
  }

  private preHandleFoldersAfterGet(folders: Folder[], info: GetAllFoldersInfo = {}): void {
    const { type = 'init', activeFolderName } = info;

    if (folders) {
      this.notificationService.handleNotifications('foldersNotificationInfo', type, {
        name: activeFolderName
      });
      this.setActiveFieldOnFolders(folders);
    }
  }

  private setActiveFieldOnFolders(folders: Folder[]): void {
    if (this.folders.length && folders.length) {
      folders.forEach((item: Folder, index: number) => {
        const prevFolder: Folder = this.folders.find((prevItem: Folder) => prevItem.id === item.id);
        folders[index].active = prevFolder?.active && !item.archived;
      });
    }
  }

  private updateFolders(folders: Folder[] = null): void {
    if (folders) {
      this.folders = folders;
    }

    this.filteredFolders = this.getFilteredFolders(folders);

    this.foldersSubject.next(this.filteredFolders);
  }

  // ******* openFolderPage *********

  updateFolderContent(params: FolderParams, resetSelection = true): void {
    const fullParams: FolderParams = FolderHandlerUtilities.getFolderParams(params);

    this.startLoading();
    this.changeUrlOnOpenFolder(params);
    this.onOpenFolderPage$.next({ params: fullParams, resetSelection });
  }

  openFolderPage(params: FolderParams, state?: FolderNavigateState): void {
    // for folders page only!
    this.startLoading();

    if (params?.id) {
      const fullParams: FolderParams = FolderHandlerUtilities.getFolderParams(params);
      const info: OpenFolderInfo = { params: fullParams, state, resetSelection: true };

      this.changeUrlOnOpenFolder(fullParams);
      this.setFolderState();
      this.setActiveFolderId(params.id);
      this.onOpenFolderPage$.next(info);
    } else {
      this.navigateToFoldersPage();
    }
  }

  private changeUrlOnOpenFolder(params: FolderParams): void {
    const id: string = params.id;
    const extras: NavigationExtras = FolderHandlerUtilities.getNavigationExtrasForFolder(params);
    const url = this.router.serializeUrl(
      this.router.createUrlTree([FolderPage.URL as string, id], extras)
    );

    this.locationService.replaceState(url);
  }

  // ******* previewFolders **********

  previewFolders(): void {
    this.startLoading();

    const state: FolderStateType = this.folderFilterInfo.state.opened ? 'opened' : 'archived';
    const access: FolderAccessType = this.folderFilterInfo.access.private ? 'private' : 'team';
    const url = this.getPreviewUrlPath(state, access);

    this.locationService.replaceState(url);

    this.setPreviewFolders(state, access);
  }

  private setPreviewLocation(): void {
    const state: FolderStateType = this.folderFilterInfo.state.opened ? 'opened' : 'archived';
    const access: FolderAccessType = this.folderFilterInfo.access.private ? 'private' : 'team';
    const url = this.getPreviewUrlPath(state, access);

    this.locationService.replaceState(url);
  }

  private getPreviewUrlPath(state: FolderStateType, access: FolderAccessType): string {
    const queryParams = FolderHandlerUtilities.getQueryParamsForPreview(state, access);

    return this.router.serializeUrl(
      this.router.createUrlTree([FolderPage.URL as string], { queryParams })
    );
  }

  setPreviewFolders(state: FolderStateType, access: FolderAccessType): void {
    this.setFilterInfoByTypes(state, access);
    this.setFoldersState();
    this.setActiveFolderId(null);
    this.finishLoading();

    if (!this.areFoldersGet) {
      this.dispatchGetAllFolders({ type: 'init' })
        .pipe(take(1))
        .subscribe(() => {});
    }
  }

  // ******* navigateToFolderPage *********

  navigateToFolderPage(
    params: FolderParams,
    state: FolderNavigateState = null,
    inNewTab: boolean = false
  ): Promise<boolean> {
    if (params) {
      FolderHandlerUtilities.handleFolderQueryParams(params);

      const id: string = params.id;
      const extras: NavigationExtras = FolderHandlerUtilities.getNavigationExtrasForFolder(
        params,
        state
      );

      if (inNewTab) {
        const url = this.router.serializeUrl(
          this.router.createUrlTree([FolderPage.URL as string, id], extras)
        );

        window.open(url);

        return new Promise<boolean>(null);
      } else {
        return this.routesHandler.routerNavigateByUrlWithExtras({
          url: FolderPage.URL as string,
          extras,
          id
        });
      }
    }
  }

  // Create folder with modal:

  createFolder(): Observable<Folder | null> {
    return this.folderItemService.createFolder();
  }

  createFolderThenUpdate(
    data: CandidatesMoveToFolderActionData = null
  ): Observable<CandidateToFolderHandlingData | CandidateFoldersInfo> {
    return this.folderItemService.createFolder().pipe(
      mergeMap((newFolder: Folder) => {
        if (newFolder) {
          return this.getCreateFolderMergeSuccess(newFolder, data);
        }

        return of(null);
      })
    );
  }

  private getCreateFolderMergeSuccess(
    newFolder: Folder,
    data: CandidatesMoveToFolderActionData = null
  ): Observable<CandidateToFolderHandlingData | CandidateFoldersInfo> {
    if (data?.candidates?.length) {
      return this.getCreateFolderMergeMapWithCandidates(newFolder, data);
    }

    this.notificationService.handleNotifications('foldersNotificationInfo', 'create', {
      name: newFolder.name
    });

    return of(null);
  }

  private getCreateFolderMergeMapWithCandidates(
    newFolder: Folder,
    data: CandidatesMoveToFolderActionData
  ): Observable<CandidateToFolderHandlingData | CandidateFoldersInfo> {
    const { candidates } = data;

    if (candidates.length === 1 && !data.type) {
      return this.getInfoByModalChosenFolders({
        docId: candidates[0].docId,
        foldersToAdd: [newFolder],
        isFolderCreated: true
      });
    }

    return this.getDuplicateCandidatesInCreatedFolderResult(candidates, data, newFolder);
  }

  private getInfoByModalChosenFolders(
    data: CandidateToFolderHandlingData
  ): Observable<CandidateToFolderHandlingData> {
    const { docId, foldersToAdd } = data;
    const requestData: AddProfileInFoldersRequestData =
      FolderHandlerUtilities.getAddProfileInFoldersRequestData(docId, foldersToAdd);

    if (requestData) {
      return this.folderService.addProfileInFolders(requestData).pipe(
        map((candidateFolders: CandidateFolder[]) => {
          return { ...data, candidateFolders };
        })
      );
    }

    return of(null);
  }

  private getDuplicateCandidatesInCreatedFolderResult(
    candidates: Candidate[],
    data: CandidatesMoveToFolderActionData,
    newFolder: Folder
  ): Observable<CandidateFoldersInfo> {
    const observable$: Observable<CandidateFoldersInfo> =
      this.getDuplicateCandidatesInCreatedFolderPipe(candidates, data, newFolder);

    return observable$.pipe(
      map((info: CandidateFoldersInfo) => {
        this.notificationService.handleDuplicateToCreatedFolderNotification(newFolder, data);

        return info;
      })
    );
  }

  private getDuplicateCandidatesInCreatedFolderPipe(
    candidates: Candidate[],
    data: CandidatesMoveToFolderActionData,
    newFolder: Folder
  ): Observable<CandidateFoldersInfo> {
    const { type, currentFolderId } = data;
    const docIds: string[] = candidates?.map((candidate: Candidate) => candidate.docId);
    const requestData: FolderMassActionDto = {
      docIds,
      folderId: currentFolderId,
      newFolderId: newFolder.id
    };

    return type === 'duplicate'
      ? this.folderService.multipleCopy(requestData)
      : type === 'move'
      ? this.folderService.multipleMove(requestData)
      : of(null);
  }
}
