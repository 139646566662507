import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CustomDropdownComponent } from 'src/app/modules/platform/components';
import { ProfileSearchEnum } from 'src/app/shared/enums';
import { PaginationInfo } from 'src/app/shared/models';
import { PaginationService } from 'src/app/shared/services';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  constructor(private paginationService: PaginationService) {}

  @Input() set paginationInfo(paginationInfo: PaginationInfo) {
    if (paginationInfo) {
      const { page, pageSize, numFound } = paginationInfo;

      this.activePage = page || 1;
      this.numberOfPages = this.paginationService.getNumberOfPages({
        numFound,
        pageSize
      });
    }
  }
  @Input() limitAccess = false;

  @Output() pageChanged = new EventEmitter<number>();

  @ViewChild('pageSelectionDropdown') public dropdown: CustomDropdownComponent;

  readonly maxPage = ProfileSearchEnum.maxPage;

  numberOfPages: number;
  activePage: number;

  onPrevPage(): void {
    if (this.activePage > 1) {
      const activePage = this.activePage - 1;

      if (!this.limitAccess) {
        this.activePage--;
      }

      this.pageChanged.emit(activePage);
    }
  }

  onNextPage(): void {
    if (this.activePage < this.numberOfPages && this.activePage < this.maxPage) {
      const activePage = this.activePage + 1;

      if (!this.limitAccess) {
        this.activePage++;
      }

      this.pageChanged.emit(activePage);
    }
  }

  selectPage(page: number): void {
    this.dropdown.hide();
    this.pageChanged.emit(page);
  }

  togglePaginationDropdown(): void {
    if (this.numberOfPages > 1) {
      this.dropdown.toggle();
    }
  }
}
