import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { catchError, map, mergeMap, switchMap, takeWhile, tap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { Params } from '@angular/router';

import {
  Candidate,
  CandidateFolder,
  CandidatesOnFolderInfo,
  ChangeCandidateFolderStatusModalData,
  Folder,
  FolderMassActionDto,
  FolderParams,
  FolderStatus,
  PaginationInfo,
  SelectedCandidatesInfo
} from '../../../../shared/models';
import {
  ChangeCandidatesFolderStatusModalComponent,
  MoveCandidatesToFolderModalComponent
} from '../../candidates-action-modals';
import { FolderService } from '../../../../shared/services/requests';
import { LocalizationService, PaginationService } from '../../../../shared/services';
import {
  CandidateFromFolderMoveType,
  NotificationInfoKey,
  SelectedCandidatesActionType
} from '../../../../shared/types';
import { FolderCandidatesUtilities } from './folder-candidates.utilities';
import {
  FolderHandlerService,
  FoldersNotificationsService,
  FolderStatusesService
} from '../../../system-shared/services';
import { RemoveCandidatesFromFolderModalComponent } from '../../../system-shared/components/modals';

@Injectable()
export class FolderCandidatesHandlerService {
  constructor(
    public dialog: MatDialog,
    private folderService: FolderService,
    private statusesService: FolderStatusesService,
    private folderHandler: FolderHandlerService,
    private localization: LocalizationService,
    private paginationService: PaginationService,
    private notificationService: FoldersNotificationsService
  ) {}

  private readonly notificationInfoKey: NotificationInfoKey = 'selectedCandidatesNotificationInfo';

  private filteredStatusIds: string[];
  private paginationInfo: PaginationInfo = {};

  setFilteredStatusIds(statusIds: string[]): void {
    this.filteredStatusIds = statusIds;
  }

  setPaginationInfo(info: PaginationInfo): void {
    this.paginationInfo = info;
  }

  getStatusIdsFromParams(params: Params): string[] {
    return FolderCandidatesUtilities.getStatusIdsFromParams(params);
  }

  getHandledCandidatesOnRequest(info: CandidatesOnFolderInfo): Candidate[] {
    return FolderCandidatesUtilities.getHandledCandidatesOnRequest(info);
  }

  getReturnProfilesOnAllStatusesFromParams(params: Params, filteredStatusIds: string[]): boolean {
    return FolderCandidatesUtilities.getReturnProfilesOnAllStatusesFromParams(
      params,
      filteredStatusIds
    );
  }

  // actions:

  changeCandidatesFolderStatus(folder: Folder, profiles: Candidate[]): Observable<null>;
  changeCandidatesFolderStatus(
    folder: Folder,
    profiles: null,
    totalSelected: number
  ): Observable<null>;
  changeCandidatesFolderStatus(
    folder: Folder,
    profiles?: Candidate[] | null,
    totalSelected?: number
  ): Observable<null> {
    let requestData: FolderMassActionDto = { folderId: folder.id };

    if (profiles) {
      requestData.docIds = FolderCandidatesUtilities.getCandidateDocIds(profiles);
      totalSelected = profiles.length;
    } else {
      requestData.statusIds = this.filteredStatusIds;
    }

    return this.promptUserForTargetStatus(folder, profiles, totalSelected).pipe(
      takeWhile((targetStatusId: string) => Boolean(targetStatusId)),
      tap(() => this.folderHandler.startLoading()),
      map((targetStatusId: string) => {
        requestData.newStatusId = targetStatusId;

        return requestData;
      }),
      mergeMap((requestData: FolderMassActionDto) => {
        return this.folderService.changeStatus(requestData).pipe(
          map((info) => {
            if (!info) throw null;

            return requestData.newStatusId;
          })
        );
      }),
      map((newStatusId: string) => {
        return this.filteredStatusIds.some((id: string) => newStatusId === id);
      }),
      map((targetStatusIsActive: boolean) => {
        const contentChange = targetStatusIsActive ? 0 : totalSelected;
        return this.recalculateFolderParams(folder.id, contentChange);
      }),
      map((folderParams: FolderParams) => {
        this.handleCandidatesActionNotification(profiles || totalSelected, 'changeStatus');

        this.folderHandler.updateFolderContent(folderParams);

        return null;
      }),
      catchError((error) => {
        if (error === null) return of(null);

        this.folderHandler.finishLoading();

        throw error;
      })
    );
  }

  removeCandidatesFromFolder(
    folder: Folder,
    profiles: Candidate[],
    isFoldersPage = false
  ): Observable<CandidateFolder[]> {
    const folderId: string = FolderCandidatesUtilities.getFolderId(folder);
    const docIds: string[] = FolderCandidatesUtilities.getCandidateDocIds(profiles);

    const $removeAction: Observable<CandidateFolder[]> = this.promptUserForRemoveConfirmation(
      profiles
    ).pipe(
      takeWhile((deletionConfirmed: boolean) => deletionConfirmed),
      tap(() => this.folderHandler.startLoading()),
      mergeMap(() => this.folderService.removeProfilesFromFolder({ folderId, docIds })),
      tap((folders: CandidateFolder[] = []) => {
        if (!folders) throw null;
      })
    );

    if (!isFoldersPage) return $removeAction;

    return $removeAction.pipe(
      map(() => {
        return this.recalculateFolderParams(folderId, profiles.length);
      }),
      switchMap((folderParams: FolderParams) => {
        this.handleCandidatesActionNotification(profiles, 'remove');

        this.folderHandler.updateFolderContent(folderParams);

        return this.folderHandler.dispatchGetAllFolders({ activeFolderId: folderId });
      }),
      catchError((error) => {
        this.folderHandler.finishLoading();

        return throwError(error);
      })
    );
  }

  removeAllActiveCandidatesFromFolder(folder: Folder, totalProfilesWithActiveStatuses: number) {
    const folderId: string = FolderCandidatesUtilities.getFolderId(folder);
    const statusIds = this.filteredStatusIds;

    if (!folderId || !statusIds?.length) return;

    return this.promptUserForRemoveConfirmation(totalProfilesWithActiveStatuses).pipe(
      takeWhile((deletionConfirmed: boolean) => deletionConfirmed),
      tap(() => this.folderHandler.startLoading()),
      switchMap(() => this.folderService.removeProfilesFromFolder({ folderId, statusIds })),
      switchMap((folders: CandidateFolder[]) => {
        this.handleCandidatesActionNotification(totalProfilesWithActiveStatuses, 'remove');

        const params: FolderParams = {
          id: folderId,
          statusIds: folder.statusIds
        };
        this.folderHandler.updateFolderContent(params);

        return this.folderHandler.dispatchGetAllFolders({ activeFolderId: folderId });
      }),
      catchError((error) => {
        this.folderHandler.finishLoading();

        return throwError(error);
      })
    );
  }

  moveCandidatesToFolder(
    actionType: CandidateFromFolderMoveType,
    currentFolderId: string,
    candidates: Candidate[]
  ): Observable<null>;
  moveCandidatesToFolder(
    actionType: CandidateFromFolderMoveType,
    currentFolderId: string,
    candidates: null,
    totalSelected: number
  ): Observable<null>;
  moveCandidatesToFolder(
    actionType: CandidateFromFolderMoveType,
    currentFolderId: string,
    candidates?: Candidate[] | null,
    totalSelected?: number
  ): Observable<null> {
    let requestData: FolderMassActionDto = { folderId: currentFolderId };

    if (candidates) {
      requestData.docIds = FolderCandidatesUtilities.getCandidateDocIds(candidates);
      totalSelected = candidates.length;
    } else {
      requestData.statusIds = this.filteredStatusIds;
    }

    return this.promptUserForTargetFolder(
      actionType,
      currentFolderId,
      candidates,
      totalSelected
    ).pipe(
      takeWhile((targetFolder: Folder) => Boolean(targetFolder)),
      tap(() => {
        if (actionType === 'move') this.folderHandler.startLoading();
      }),
      map((targetFolder: Folder) => {
        requestData.newFolderId = targetFolder.id;

        return requestData;
      }),
      switchMap((requestData: FolderMassActionDto) => {
        switch (actionType) {
          case 'duplicate':
            return this.folderService.multipleCopy(requestData);
          case 'move':
            return this.folderService.multipleMove(requestData);
          default:
            throw null;
        }
      }),
      map(() => {
        const reduction = actionType === 'move' ? totalSelected : 0;

        return this.recalculateFolderParams(currentFolderId, reduction);
      }),
      switchMap((folderParams: FolderParams) => {
        this.handleCandidatesActionNotification(candidates || totalSelected, actionType);

        if (actionType === 'move') {
          this.folderHandler.updateFolderContent(folderParams);
        }

        return this.folderHandler.dispatchGetAllFolders({ activeFolderId: currentFolderId });
      }),
      catchError((error) => {
        this.folderHandler.finishLoading();

        if (error === null) return of(null);

        throw error;
      })
    );
  }

  private promptUserForTargetFolder(
    actionType: CandidateFromFolderMoveType,
    currentFolderId: string,
    profiles?: Candidate[],
    profileNumber?: number
  ): Observable<Folder> {
    return this.folderService.getAllFolders(false).pipe(
      map((folders: Folder[] = []) => {
        return folders.filter(
          (folder: Folder) => !folder.archived && folder.id !== currentFolderId
        );
      }),
      switchMap((suitableFolders: Folder[]) => {
        if (suitableFolders.length) {
          const matDialog: MatDialogRef<MoveCandidatesToFolderModalComponent> =
            this.getMoveCandidatesToFolderModalDialogRef(
              actionType,
              profiles || profileNumber,
              suitableFolders
            );

          return matDialog.afterClosed();
        } else {
          return this.folderHandler.createFolder();
        }
      })
    );
  }

  // remove candidates methods:

  private promptUserForRemoveConfirmation(profiles: number | Candidate[]): Observable<boolean> {
    let name = '';
    let multipleInfo: string | null = null;

    if (typeof profiles === 'number') {
      multipleInfo = this.getCandidatesActionNotifyMultipleInfo(profiles, 'remove');
    }

    if (typeof profiles === 'object') {
      if (profiles.length === 1) {
        name = FolderCandidatesUtilities.getCandidateName(profiles[0]);
      } else {
        multipleInfo = this.getCandidatesActionNotifyMultipleInfo(profiles.length, 'remove');
      }
    }

    return this.dialog
      .open(RemoveCandidatesFromFolderModalComponent, {
        data: { name, multipleInfo }
      })
      .afterClosed();
  }

  private recalculateFolderParams(folderId: string, contentReduction: number): FolderParams {
    const newNumFound: number = this.paginationInfo.numFound - contentReduction;
    const numberOfPages: number = this.paginationService.getNumberOfPages({
      numFound: newNumFound,
      pageSize: this.paginationInfo.pageSize
    });

    const page =
      numberOfPages < this.paginationInfo.page ? numberOfPages : this.paginationInfo.page;

    return {
      id: folderId,
      page,
      statusIds: this.filteredStatusIds,
      count: this.paginationInfo.pageSize
    };
  }

  private promptUserForTargetStatus(
    folder: Folder,
    candidates?: Candidate[],
    candidateNumber?: number
  ): Observable<string> {
    const statusesList: FolderStatus[] = this.statusesService.getFolderStatusesListFromInfo({
      info: folder.statuses
    });

    let data: ChangeCandidateFolderStatusModalData = { statuses: statusesList };

    if (candidateNumber) {
      data.candidateLength = candidateNumber;
    }

    if (candidates) {
      if (candidates.length === 1) {
        data.candidateName = FolderCandidatesUtilities.getCandidateName(candidates[0]);
      }

      if (candidates.length > 1) {
        data.candidateLength = candidates.length;
      }

      if (FolderCandidatesUtilities.haveTheSameStatus(candidates)) {
        data.selectedStatusId = candidates[0].currentStatusId;
      }
    }

    return this.dialog.open(ChangeCandidatesFolderStatusModalComponent, { data }).afterClosed();
  }

  // methods for moving candidates:

  private getMoveCandidatesToFolderModalDialogRef(
    type: CandidateFromFolderMoveType,
    candidates: number | Candidate[],
    folders: Folder[]
  ): MatDialogRef<MoveCandidatesToFolderModalComponent> {
    if (typeof candidates === 'number') {
      return this.dialog.open(MoveCandidatesToFolderModalComponent, {
        data: {
          type,
          folders,
          candidateLength: candidates
        }
      });
    }

    const candidateLength: number = candidates?.length;
    const candidateName: string =
      FolderCandidatesUtilities.getCandidateNameByCandidates(candidates);

    return this.dialog.open(MoveCandidatesToFolderModalComponent, {
      data: {
        type,
        folders,
        candidateLength,
        candidateName
      }
    });
  }

  getCandidatesCountByFolder(folder: Folder): number {
    return folder.hasOwnProperty('candidatesCountByStatuses')
      ? folder.candidatesCountByStatuses
      : folder.candidatesCount;
  }

  // Candidates common methods:

  handleCandidatesActionNotification(
    candidates: number | Candidate[],
    actionType: SelectedCandidatesActionType
  ): void {
    if (typeof candidates === 'number') {
      this.handleMultipleCandidatesActionNotification(actionType, candidates);
      return;
    }

    const candidatesNumber: number = candidates?.length;

    if (candidatesNumber === 1) {
      this.handleCandidateActionNotification(candidates, actionType);
    } else if (candidatesNumber > 1) {
      this.handleMultipleCandidatesActionNotification(actionType, candidatesNumber);
    }
  }

  private handleCandidateActionNotification(
    candidates: Candidate[],
    actionType: SelectedCandidatesActionType
  ): void {
    const candidateName: string =
      FolderCandidatesUtilities.getCandidateNameByCandidates(candidates);
    const translatedName: string = this.localization.translateInstant(candidateName);

    this.notificationService.handleNotifications(this.notificationInfoKey, actionType, {
      name: translatedName
    });
  }

  private handleMultipleCandidatesActionNotification(
    actionType: SelectedCandidatesActionType,
    candidatesNumber: number
  ): void {
    const multipleInfo: string = this.getCandidatesActionNotifyMultipleInfo(
      candidatesNumber,
      actionType
    );

    this.notificationService.handleNotifications(this.notificationInfoKey, actionType, {
      multipleInfo
    });
  }

  private getCandidatesActionNotifyMultipleInfo(
    candidatesNumber: number,
    actionType: SelectedCandidatesActionType
  ): string {
    const candidateNumeric: string = this.localization.getCurrentNumeric(
      candidatesNumber,
      actionType === 'changeStatus' ? 'FOR_PROFILES_STATUS' : 'PROFILE'
    );

    const candidateNumericTranslated: string = this.localization.translateInstant(candidateNumeric);

    return `${candidatesNumber} ${candidateNumericTranslated}`;
  }
}
