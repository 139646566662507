import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewContainerRef
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { SafeUrl } from '@angular/platform-browser';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';

import {
  AddCandidateToFolderModalData,
  Candidate,
  CandidateActionsInfo,
  CandidateModalData,
  CandidateToFolderActionInfo,
  ConditionInfo
} from '../../../../../../shared/models';
import { CandidateCardHandlerService, ProfileService } from '../../../../../../shared/services';
import { SystemColors } from '../../../../../../shared/types';
import { CandidateToFoldersService, FoldersNotificationsService } from '../../../../services';
import { AddCommentModalComponent, AddTagModalComponent } from '../../../modals';

@Component({
  selector: 'app-candidate-card-actions',
  templateUrl: './candidate-card-actions.component.html',
  styleUrls: ['./candidate-card-actions.component.scss']
})
export class CandidateCardActionsComponent implements OnInit, OnDestroy {
  constructor(
    public dialog: MatDialog,
    private profileService: ProfileService,
    private candidateToFolders: CandidateToFoldersService,
    private cardHandler: CandidateCardHandlerService,
    private viewContainerRef: ViewContainerRef,
    private foldersNotifications: FoldersNotificationsService
  ) {}

  @Input() type: 'standard' | 'forFolder' = 'standard';
  @Input() candidate: Candidate;
  @Input() index = 0;
  @Input() picture: SafeUrl;
  @Input() revealed = false;

  @Output() removeFromFolder = new EventEmitter<null>();

  private isAddToFolderStarted = false;
  private updateIsAddToFoldersStartedInfo$ = new BehaviorSubject<ConditionInfo>({
    condition: this.isAddToFolderStarted
  });
  private unsubscribe$ = new Subject<void>();

  actionsInfo$: Observable<CandidateActionsInfo>;
  isAddToFolderStartedInfo$: Observable<ConditionInfo> =
    this.updateIsAddToFoldersStartedInfo$.asObservable();

  ngOnInit(): void {
    this.setActionsInfo();
  }

  addComment(): void {
    const data: CandidateModalData = {
      candidate: this.candidate,
      index: this.index
    };

    this.dialog.open(AddCommentModalComponent, {
      viewContainerRef: this.viewContainerRef,
      data
    });
  }

  addTag(): void {
    if (this.candidate?.docId) {
      const data: CandidateModalData = {
        candidate: this.candidate,
        index: this.index
      };

      this.dialog.open(AddTagModalComponent, {
        viewContainerRef: this.viewContainerRef,
        data
      });
    }
  }

  onFolderAction(): void {
    if (this.type === 'forFolder') {
      this.removeFromFolder.emit(null);
    } else {
      this.addCandidateToFolder();
    }
  }

  private addCandidateToFolder(): void {
    if (!this.isAddToFolderStarted) {
      this.setAddCandidateToFolderStarted(true);

      const defaultColor: SystemColors = this.profileService.getCandidateDefaultColorByIndex(
        this.index
      );
      const data: AddCandidateToFolderModalData = {
        docId: this.candidate?.docId,
        defaultColor,
        candidate: this.candidate
      };

      this.candidateToFolders
        .addCandidateToFolder(data)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            this.resetIsAddCandidateToFolderStarted();

            return throwError(error);
          }),
          takeUntil(this.unsubscribe$)
        )
        .subscribe((info: CandidateToFolderActionInfo) => {
          this.updateFoldersCountByInfo(info);
          this.resetIsAddCandidateToFolderStarted();
          this.foldersNotifications.handleCandidateToFolderNotification(info);
        });
    }
  }

  private setAddCandidateToFolderStarted(started: boolean): void {
    this.isAddToFolderStarted = started;

    this.updateIsAddToFoldersStartedInfo$.next({ condition: started });
  }

  private setActionsInfo(): void {
    this.actionsInfo$ = this.cardHandler.actionsInfo$.pipe(
      map((info: CandidateActionsInfo) => info)
    );
  }

  private updateFoldersCountByInfo(info: CandidateToFolderActionInfo): void {
    if (typeof info?.folders?.length === 'number') {
      this.cardHandler.updateFoldersCount(info.folders.length);
    }
  }

  private resetIsAddCandidateToFolderStarted(): void {
    if (this.isAddToFolderStarted) {
      this.setAddCandidateToFolderStarted(false);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
