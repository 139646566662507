import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import {
  ChangeCandidateFolderStatusModalData,
  FolderStatus,
  ModalInfo
} from '../../../../shared/models';

@Component({
  selector: 'app-change-candidates-folder-status-modal',
  templateUrl: './change-candidates-folder-status-modal.component.html',
  styleUrls: ['./change-candidates-folder-status-modal.component.scss']
})
export class ChangeCandidatesFolderStatusModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ChangeCandidatesFolderStatusModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ChangeCandidateFolderStatusModalData
  ) {}

  info: ModalInfo = {
    type: 'simple-modal',
    extraCssClass: 'change-candidate-folder-status-modal',
    closeModalBtnId: 'close-change-candidates-status-m',
    header: {
      title: 'FOLDERS.FOLDER_PAGE.CANDIDATE_ACTIONS.CHANGE_STATUS',
      textContent: true,
      withForm: true
    },
    actions: {
      cancel: {
        id: 'cancel-change-candidates-status-btn'
      },
      submit: {
        id: 'm-change-candidates-status--btn',
        extraClass: 'bright',
        type: 'change'
      }
    }
  };

  selectedStatusId: string;

  ngOnInit(): void {
    this.setData();
  }

  closeModal(statusId: string = null): void {
    this.dialogRef.close(statusId);
  }

  changeStatus(status: FolderStatus): void {
    if (status) {
      this.selectedStatusId = status.id;

      this.setActiveField();
      this.handleSubmitDisabledCondition();
    }
  }

  saveChanging(): void {
    if (this.selectedStatusId) {
      this.closeModal(this.selectedStatusId);
    }
  }

  private setData(): void {
    if (this.data?.selectedStatusId) {
      this.selectedStatusId = this.data.selectedStatusId;
    }

    this.setActiveField();
    this.handleSubmitDisabledCondition();
  }

  private handleSubmitDisabledCondition(): void {
    this.info.actions.submit.disabled =
      !this.selectedStatusId || this.selectedStatusId === this.data?.selectedStatusId;
  }

  private setActiveField(): void {
    if (this.data.statuses) {
      this.data.statuses.forEach((item, index) => {
        this.data.statuses[index] = {
          ...item,
          active: item.id === this.selectedStatusId
        };
      });
    }
  }
}
