import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ModalInfo } from '../../../../shared/models';

@Component({
  selector: 'app-archive-folder-modal',
  templateUrl: './archive-folder-modal.component.html',
  styleUrls: ['./archive-folder-modal.component.scss']
})
export class ArchiveFolderModalComponent {
  constructor(
    public dialogRef: MatDialogRef<ArchiveFolderModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { name: string; toArchive: boolean }
  ) {}

  info: ModalInfo = {
    type: 'alert-modal',
    closeModalBtnId: 'close-archive-folder-m',
    header: {
      titleContent: true
    },
    actions: {
      cancel: {
        id: 'm-cancel-archive-folder-btn'
      },
      submitWithClothing: {
        content: true
      }
    }
  };

  closeModal(condition: boolean = false): void {
    this.dialogRef.close(condition);
  }
}
