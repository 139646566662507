import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CheckBoxData } from '../../../../shared/models';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent {
  constructor() {}

  @Input() data: CheckBoxData;

  @Input() wrapperId: string = '';
  @Input() checkboxId: string = '';

  @Output() valueChanged = new EventEmitter<null>();

  onValueChanged(): void {
    this.data.checked = !this.data.checked;

    this.valueChanged.emit(null);
  }
}
