import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { Account, User } from '../../models';
import { TariffsHandlerUtilities } from './tariffs/tariffs-handler.utilities';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  constructor() {}

  private currentAccountInfoSubject = new BehaviorSubject<Account>(null);
  private updateAccountSubject = new Subject<{
    withMarkers?: boolean;
    user?: User;
  }>();

  currentAccountInfo$ = this.currentAccountInfoSubject.asObservable();
  updateAccount$ = this.updateAccountSubject.asObservable();

  getIsAccountBlocked(accountInfo: Account): boolean {
    return accountInfo?.status === 'blocked';
  }

  getIsFreeTariff(account: Account): boolean {
    return TariffsHandlerUtilities.isTariffFree(account.tariff);
  }

  getIsAccountLimited(account: Account): boolean {
    const isBlocked: boolean = this.getIsAccountBlocked(account);
    const isFree: boolean = this.getIsFreeTariff(account);

    return isBlocked || isFree;
  }

  updateAccountInfo(account: Account): void {
    this.currentAccountInfoSubject.next(account);
  }

  updateAccount(withMarkers: boolean = false, user: User = null): void {
    this.updateAccountSubject.next({ withMarkers, user });
  }
}
