<app-modal
  [info]="info"
  (closeModal)="closeModal($event)">
  <ng-template #titleContent>
    <h3
      *ngIf="data.toArchive"
      class="modal__title">
      {{ 'FOLDERS.ARCHIVE_FOLDER_MODAL.ARCHIVE_TITLE' | translate: { name: data?.name } }}
    </h3>

    <h3
      *ngIf="!data.toArchive"
      class="modal__title">
      {{ 'FOLDERS.ARCHIVE_FOLDER_MODAL.UNARCHIVE_TITLE' | translate: { name: data?.name } }}
    </h3>
  </ng-template>

  <ng-template #submitWithClothingContent>
    <button
      *ngIf="data.toArchive"
      id="m-archive-folder-btn"
      (click)="closeModal(true)"
      class="btn btn--new-primary small alert modal-btn">
      <span class="btn__text">
        {{ 'GENERAL.YES_ARCHIVE' | translate }}
      </span>
    </button>

    <button
      *ngIf="!data.toArchive"
      id="m-unarchive-folder-btn"
      (click)="closeModal(true)"
      class="btn btn--new-primary small alert modal-btn">
      <span class="btn__text">
        {{ 'GENERAL.YES_UNARCHIVE' | translate }}
      </span>
    </button>
  </ng-template>
</app-modal>
