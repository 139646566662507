<app-modal
  [info]="info"
  (closeModal)="closeModal($event)"
  (onSubmit)="saveChanging()">
  <div class="folder-statuses__list">
    <app-folder-status
      *ngFor="let status of data?.statuses"
      class="folder-statuses__item"
      [ngClass]="{'active': status.id === data.selectedStatusId}"
      [status]="status"
      [type]="'single-change-only'"
      [selectable]="true"
      [active]="status.active"
      (clickOnStatus)="changeStatus(status)">
    </app-folder-status>
  </div>

  <ng-template #textContent>
    <p class="modal__text">
      <ng-container [ngTemplateOutlet]="candidateInfo"></ng-container>
    </p>
  </ng-template>
</app-modal>

<ng-template #candidateInfo>
  <ng-container *ngIf="data?.candidateName">
    {{ data.candidateName | translate }}
  </ng-container>

  <ng-container *ngIf="data?.candidateLength > 1">
    {{ data.candidateLength | currentNumeric: 'FOR_PROFILES_NUMBER' | translate : { n: data.candidateLength } }}
  </ng-container>
</ng-template>
