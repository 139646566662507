import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { FolderService } from '../../../../shared/services/requests';
import {
  AddCandidateToFolderModalData,
  AddCandidateToFolderModalResult,
  AddProfileInFoldersRequestData,
  CandidateFolder,
  CandidateToFolderActionInfo,
  CandidateToFolderHandlingData,
  Folder
} from '../../../../shared/models';
import { CandidateToFolderActionType } from '../../../../shared/types';
import { FolderHandlerService } from './folder-handler.service';
import { AddCandidateToFolderModalComponent } from '../../components/folders';

@Injectable({
  providedIn: 'root'
})
export class CandidateToFoldersService {
  constructor(
    public dialog: MatDialog,
    private folderService: FolderService,
    private folderHandler: FolderHandlerService
  ) {}

  private candidateFoldersSubject = new BehaviorSubject<CandidateToFolderActionInfo>(null);

  candidateFolders$: Observable<CandidateToFolderActionInfo> =
    this.candidateFoldersSubject.asObservable();

  addCandidateToFolder(
    data: AddCandidateToFolderModalData
  ): Observable<CandidateToFolderActionInfo> {
    return this.folderService.getAllFolders(false, [data.docId]).pipe(
      mergeMap((folders: Folder[]) => {
        return this.getInfoByFolders({ ...data, folders });
      }),
      catchError(() => of(null))
    );
  }

  updateCandidateFolders(info: CandidateToFolderActionInfo): void {
    this.candidateFoldersSubject.next(info);
  }

  private getInfoByFolders(
    data: AddCandidateToFolderModalData
  ): Observable<CandidateToFolderActionInfo> {
    const openFolderList: Folder[] = data?.folders?.filter((folder: Folder) => !folder.archived);

    if (openFolderList?.length) {
      return this.getInfoByFoldersIfOpenedExist({
        ...data,
        folders: openFolderList
      });
    }

    return this.folderHandler.createFolderThenUpdate({ candidates: [data.candidate] }).pipe(
      map((handlingData: CandidateToFolderHandlingData) => {
        return this.getInfoAfterAddingFolders(handlingData);
      })
    );
  }

  private getInfoByFoldersIfOpenedExist(
    data: AddCandidateToFolderModalData
  ): Observable<CandidateToFolderActionInfo> {
    const matDialog: MatDialogRef<AddCandidateToFolderModalComponent> = this.dialog.open(
      AddCandidateToFolderModalComponent,
      {
        data
      }
    );

    return matDialog.afterClosed().pipe(
      mergeMap((info: AddCandidateToFolderModalResult) => {
        if (info) {
          return this.getInfoByModalChosenFolders({
            ...info,
            docId: data?.docId,
            isFolderCreated: false
          });
        }

        return of(null);
      })
    );
  }

  private getInfoByModalChosenFolders(
    data: CandidateToFolderHandlingData
  ): Observable<CandidateToFolderActionInfo> {
    const { docId, foldersToAdd = [], foldersToRemove = [] } = data;
    const foldersToAddData: AddProfileInFoldersRequestData = this.getFoldersToAddData(
      docId,
      foldersToAdd
    );
    const folderIdsToRemove: string[] = foldersToRemove.map((folder: Folder) => folder.id);
    const removeProfileFromFolders$ = folderIdsToRemove?.length
      ? this.folderService.removeProfileFromFolders(docId, folderIdsToRemove)
      : of(null);

    return removeProfileFromFolders$.pipe(
      mergeMap((candidateFolders: CandidateFolder[]) => {
        if (foldersToAddData) {
          return this.folderService.addProfileInFolders(foldersToAddData);
        }

        return of(candidateFolders);
      }),
      map((candidateFolders: CandidateFolder[] = []) => {
        return this.getInfoAfterAddingFolders({ ...data, candidateFolders });
      })
    );
  }

  private getInfoAfterAddingFolders(
    data: CandidateToFolderHandlingData
  ): CandidateToFolderActionInfo {
    if (!data) {
      return null;
    }

    const { docId, candidateFolders = [] } = data;
    const actionType: CandidateToFolderActionType =
      CandidateToFoldersService.getAddCandidateToFoldersActionType(data);
    const folderName: string = CandidateToFoldersService.getAddCandidateToFolderName(data);

    const info: CandidateToFolderActionInfo = {
      docId,
      actionType,
      folderName,
      folders: candidateFolders
    };

    this.updateCandidateFolders(info);

    return info;
  }

  private static getAddCandidateToFolderName(data: CandidateToFolderHandlingData): string {
    const { foldersToAdd = [], foldersToRemove = [] } = data;

    return foldersToAdd?.length === 1 && !foldersToRemove?.length
      ? foldersToAdd[0].name
      : foldersToRemove?.length === 1 && !foldersToAdd?.length
      ? foldersToRemove[0].name
      : '';
  }

  private static getAddCandidateToFoldersActionType(
    data: CandidateToFolderHandlingData
  ): CandidateToFolderActionType {
    const { foldersToAdd = [], foldersToRemove = [], isFolderCreated } = data;
    let actionType: CandidateToFolderActionType;

    if (foldersToAdd?.length && foldersToRemove.length) {
      actionType = 'addAndRemoveFromFolders';
    } else if (foldersToAdd?.length) {
      actionType =
        foldersToAdd?.length > 1
          ? 'addToFolders'
          : isFolderCreated
          ? 'addToCreatedFolder'
          : 'addToFolder';
    } else if (foldersToRemove?.length) {
      actionType = foldersToRemove?.length > 1 ? 'removeFromFolders' : 'removeFromFolder';
    }

    return actionType;
  }

  private getFoldersToAddData(
    docId: string,
    foldersToAdd: Folder[] = []
  ): AddProfileInFoldersRequestData {
    if (foldersToAdd?.length) {
      const profilesToAdd: CandidateFolder[] = foldersToAdd.map((folder: Folder) => {
        return {
          folderId: folder.id,
          docId,
          statusId: folder.statusIds[0]
        };
      });

      return { profilesToAdd };
    }

    return null;
  }
}
