import {
  AutocompleteResultNameEnum,
  CategoryQueryParamEnum,
  FilterParam,
  SearchCategoryTypeEnum
} from '../../../../../shared/enums';
import { LevelFilterData } from './level-filter.data';
import { RangeFilterData } from './range-filter.data';
import { CompanySizeFilterData } from './company-size-filter.data';
import { IndustryFilterData } from './industry-filter.data';
import { LanguageFilterData } from './language-filter.data';
import { ContactsFilterData } from './contacts-filter.data';
import { ContactFilter, ReviewedFilter, ReviewedFilterInfo } from '../../../../../shared/classes';
import { SearchFilter, SearchFilterQuery } from '../../../../system-shared/classes';
import { SearchFilterType } from '../../../../system-shared/data';
import { RangeInfoFilterQueryItem } from 'src/app/modules/system-shared/classes/search-filters/range-info-filter-query-item';
import { SpotlightsFilterData } from './spotlights-filter.data';

export class FiltersData {
  static getDefaultFilters(): SearchFilter[] {
    return [
      new SearchFilter({
        label: 'FILTER.LOCATION.LABEL',
        revealed: true,
        queries: [
          new SearchFilterQuery({
            type: 'query',
            filterType: SearchFilterType.INPUT,
            queryParam: CategoryQueryParamEnum.byCurrentLocation,
            searchType: SearchCategoryTypeEnum.currentLocation,
            autocompleteType: AutocompleteResultNameEnum.locationsCurrent,
            label: 'FILTER.LOCATION.MAIN_LABEL',
            wrapperInfo: { withWrapper: true, withWrapperOnAutocomplete: false }
          })
        ],
        extraQueryInfo: {
          type: 'query',
          queryParam: CategoryQueryParamEnum.byLocation,
          searchType: SearchCategoryTypeEnum.location,
          autocompleteType: AutocompleteResultNameEnum.locations,
          addBtnLabel: 'FILTER.LOCATION.EXTRA_BTN',
          label: 'FILTER.LOCATION.EXTRA_LABEL',
          wrapperInfo: { withWrapper: true, withWrapperOnAutocomplete: false },
          matcherInfo: { withMatcher: true }
        }
      }),
      new SearchFilter({
        label: 'FILTER.POSITION.LABEL',
        queries: [
          new SearchFilterQuery({
            type: 'query',
            filterType: SearchFilterType.INPUT,
            queryParam: CategoryQueryParamEnum.byCurrentPosition,
            searchType: SearchCategoryTypeEnum.currentPosition,
            autocompleteType: AutocompleteResultNameEnum.positionSynonymDtosCurrent,
            label: 'FILTER.POSITION.MAIN_LABEL',
            matcherInfo: { withMatcher: true },
            wrapperInfo: { withWrapper: true, withWrapperOnAutocomplete: true }
          })
        ],
        extraQueryInfo: {
          type: 'query',
          queryParam: CategoryQueryParamEnum.byPosition,
          searchType: SearchCategoryTypeEnum.position,
          autocompleteType: AutocompleteResultNameEnum.positionSynonymDtos,
          addBtnLabel: 'FILTER.POSITION.EXTRA_BTN',
          label: 'FILTER.POSITION.EXTRA_LABEL',
          matcherInfo: { withMatcher: true },
          wrapperInfo: { withWrapper: true, withWrapperOnAutocomplete: true }
        }
      }),
      new SearchFilter({
        label: 'FILTER.SKILLS.LABEL',
        queries: [
          new SearchFilterQuery({
            type: 'query',
            filterType: SearchFilterType.INPUT,
            queryParam: CategoryQueryParamEnum.bySkill,
            searchType: SearchCategoryTypeEnum.skill,
            autocompleteType: AutocompleteResultNameEnum.skills,
            matcherInfo: { withMatcher: true },
            hasQueryDuplicates: true,
            wrapperInfo: { withWrapper: true, withWrapperOnAutocomplete: true }
          })
        ],
        extraQueryInfo: {
          type: 'query',
          queryParam: CategoryQueryParamEnum.bySkill,
          searchType: SearchCategoryTypeEnum.skill,
          autocompleteType: AutocompleteResultNameEnum.skills,
          addBtnLabel: 'FILTER.SKILLS.EXTRA_BTN',
          label: 'FILTER.SKILLS.EXTRA_LABEL',
          matcherInfo: { withMatcher: true },
          hasQueryDuplicates: true,
          wrapperInfo: { withWrapper: true, withWrapperOnAutocomplete: true }
        }
      }),
      new SearchFilter({
        label: 'FILTER.NAME.LABEL',
        queries: [
          new SearchFilterQuery({
            type: 'query',
            filterType: SearchFilterType.INPUT,
            queryParam: CategoryQueryParamEnum.byName,
            searchType: SearchCategoryTypeEnum.name,
            wrapperInfo: { withWrapper: true }
          })
        ]
      }),
      new SearchFilter({
        label: 'FILTER.KEYWORDS.LABEL',
        queries: [
          new SearchFilterQuery({
            type: 'query',
            filterType: SearchFilterType.INPUT,
            queryParam: CategoryQueryParamEnum.byWord,
            searchType: SearchCategoryTypeEnum.default,
            wrapperInfo: { withWrapper: true },
            matcherInfo: { withMatcher: true }
          })
        ]
      }),
      new SearchFilter({
        label: 'FILTER.EXPERIENCE.LABEL',
        queries: [
          new SearchFilterQuery({
            type: 'filter',
            filterType: SearchFilterType.CHECKBOX,
            filterParam: FilterParam.byLevel,
            facetType: 'levels',
            label: 'FILTER.EXPERIENCE.LEVEL',
            info: {
              filterItemList: SearchFilterQuery.getCheckboxItemList(
                LevelFilterData.getInitLevelListForFilter()
              )
            }
          }),
          new SearchFilterQuery({
            type: 'filter',
            filterType: SearchFilterType.RANGE_SLIDER,
            filterParam: FilterParam.byExperience,
            label: 'FILTER.EXPERIENCE.GENERAL',
            info: {
              rangesInfo: new RangeInfoFilterQueryItem(RangeFilterData.getInitExperienceRange())
            }
          }),
          new SearchFilterQuery({
            type: 'filter',
            filterType: SearchFilterType.RANGE_SLIDER,
            filterParam: FilterParam.byLastExperience,
            label: 'FILTER.EXPERIENCE.LAST_POSITION',
            info: {
              rangesInfo: new RangeInfoFilterQueryItem(RangeFilterData.getInitExperienceRange())
            }
          })
        ]
      }),
      new SearchFilter({
        label: 'FILTER.SPOTLIGHTS.LABEL',
        queries: [
          new SearchFilterQuery({
            type: 'filter',
            filterType: SearchFilterType.CHECKBOX,
            filterParam: FilterParam.byOpenToWork,
            facetType: 'openToWork',
            info: {
              filterItemList: SearchFilterQuery.getCheckboxItemList(
                SpotlightsFilterData.getFilterList()
              )
            }
          })
        ],
        forFullPlanOnly: true
      }),
      new SearchFilter({
        label: 'FILTER.COMPANY.LABEL',
        queries: [
          new SearchFilterQuery({
            type: 'query',
            filterType: SearchFilterType.INPUT,
            queryParam: CategoryQueryParamEnum.byCurrentCompany,
            searchType: SearchCategoryTypeEnum.currentCompany,
            autocompleteType: AutocompleteResultNameEnum.companyDtosCurrent,
            label: 'FILTER.COMPANY.MAIN_LABEL',
            wrapperInfo: { withWrapper: true }
          }),
          new SearchFilterQuery({
            type: 'filter',
            filterType: SearchFilterType.CHECKBOX,
            filterParam: FilterParam.byCompanySize,
            label: 'FILTER.COMPANY.COMPANY_SIZE',
            facetType: 'companySize',
            info: {
              filterItemList: SearchFilterQuery.getCheckboxItemList(
                CompanySizeFilterData.getFilterList()
              )
            }
          })
        ],
        extraQueryInfo: {
          type: 'query',
          queryParam: CategoryQueryParamEnum.byCompany,
          searchType: SearchCategoryTypeEnum.company,
          autocompleteType: AutocompleteResultNameEnum.companyDtos,
          addBtnLabel: 'FILTER.COMPANY.EXTRA_BTN',
          label: 'FILTER.COMPANY.EXTRA_LABEL',
          wrapperInfo: { withWrapper: true },
          matcherInfo: { withMatcher: true }
        }
      }),
      new SearchFilter({
        label: 'FILTER.INDUSTRY.LABEL',
        queries: [
          new SearchFilterQuery({
            type: 'filter',
            filterType: SearchFilterType.CHECKBOX,
            filterParam: FilterParam.byIndustry,
            facetType: 'industry',
            checkedCheckboxOnTop: true,
            info: {
              filterItemList: SearchFilterQuery.getCheckboxItemList(
                IndustryFilterData.getInitListForFilter()
              )
            },
            wrapperInfo: { withWrapper: true }
          })
        ]
      }),
      new SearchFilter({
        label: 'FILTER.EDUCATION.LABEL',
        queries: [
          new SearchFilterQuery({
            type: 'query',
            filterType: SearchFilterType.INPUT,
            queryParam: CategoryQueryParamEnum.byEducation,
            searchType: SearchCategoryTypeEnum.education,
            label: 'FILTER.EDUCATION.UNIVERSITY',
            wrapperInfo: { withWrapper: true }
          }),
          new SearchFilterQuery({
            type: 'filter',
            filterType: SearchFilterType.CHECKBOX,
            filterParam: FilterParam.byLanguage,
            label: 'FILTER.EDUCATION.LANGUAGES_SPOKEN',
            facetType: 'languages',
            checkedCheckboxOnTop: true,
            info: {
              filterItemList: SearchFilterQuery.getCheckboxItemList(
                LanguageFilterData.getFilterList()
              )
            }
          })
        ]
      }),
      new SearchFilter({
        label: 'FILTER.CERTIFICATE.LABEL',
        queries: [
          new SearchFilterQuery({
            type: 'query',
            filterType: SearchFilterType.INPUT,
            queryParam: CategoryQueryParamEnum.byCertificate,
            searchType: SearchCategoryTypeEnum.certificate,
            wrapperInfo: { withWrapper: true }
          })
        ]
      }),
      /*new SearchFilter({
        label: 'FILTER.CONTACTS.LABEL',
        queries: [
          new SearchFilterQuery({
            type: 'filter',
            filterType: SearchFilterType.CONTACTS,
            filterParam: FilterParam.withContacts,
            facetType: 'contacts',
            info: {
              contactsInfo: new ContactFilter(ContactsFilterData.getInitContactsFilterInfo())
            }
          })
        ]
      }),*/
      new SearchFilter({
        label: 'FILTER.COMMENTS_AND_TAGS.LABEL',
        queries: [
          new SearchFilterQuery({
            type: 'query',
            filterType: SearchFilterType.INPUT,
            queryParam: CategoryQueryParamEnum.byTag,
            searchType: SearchCategoryTypeEnum.tag,
            label: 'TAGS.TAGS',
            autocompleteType: AutocompleteResultNameEnum.tags,
            autocompleteOnInit: true,
            matcherInfo: { withMatcher: true }
          }),
          new SearchFilterQuery({
            type: 'query',
            filterType: SearchFilterType.INPUT,
            queryParam: CategoryQueryParamEnum.byComment,
            searchType: SearchCategoryTypeEnum.comment,
            label: 'COMMENT.COMMENTS',
            matcherInfo: { withMatcher: true }
          })
        ]
      }),
      new SearchFilter({
        label: 'FILTER.REVIEWED.LABEL',
        queries: [
          new SearchFilterQuery({
            type: 'filter',
            filterType: SearchFilterType.REVIEWED,
            filterParam: FilterParam.notViewed,
            filterParamMultiple: [
              FilterParam.notViewed,
              FilterParam.notViewedLastDays,
              FilterParam.notViewedByAccount,
              FilterParam.notViewedByAccountLastDays
            ],
            notComposeParams: true,
            info: {
              reviewedFilterInfo: new ReviewedFilter(ReviewedFilterInfo.getNotViewedInitData())
            }
          })
        ],
        forFullPlanOnly: true
      })
    ];
  }
}
