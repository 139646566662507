import { Autocomplete, SearchQueryItemInfo } from '../../../../../shared/models';
import { FiltersData } from './filters.data';
import { searchItemQuerySeparator, searchQuerySeparator } from '../../../../../shared/utilities';
import { CategoryQueryParamEnum } from '../../../../../shared/enums';
import {
  ParseFiltersInfo,
  SearchFilterQueryType
} from '../../../../system-shared/models/search-filter';
import { SearchFilter, SearchFilterQuery } from '../../../../system-shared/classes';

interface SetDataToFilterQueriesInfo {
  parseFilterInfo: ParseFiltersInfo;
  queriesInfo: SearchQueryItemInfo[];
  filtersInfo: SearchQueryItemInfo[];
}

export class FiltersUtilities {
  // ******************************** Public availability ********************************
  static getDefaultSearchFilters(defaultLocation: Autocomplete): SearchFilter[] {
    const filters: SearchFilter[] = FiltersData.getDefaultFilters();

    filters.find((item: SearchFilter) => {
      const currentLocationQuery: SearchFilterQuery = item.data.queries.find(
        (query: SearchFilterQuery) =>
          query.data.queryParam === CategoryQueryParamEnum.byCurrentLocation
      );

      if (currentLocationQuery) {
        currentLocationQuery.setItemValueByAutocomplete(defaultLocation);
      }

      return !!currentLocationQuery;
    });

    return filters;
  }

  static getParsedFilters(parseFilterInfo: ParseFiltersInfo): SearchFilter[] {
    const filters: SearchFilter[] = FiltersData.getDefaultFilters();
    const { requestInfo } = parseFilterInfo;
    const { q, f } = requestInfo;
    const queriesInfo: SearchQueryItemInfo[] = FiltersUtilities.parseQueryInfoList(q);
    const filtersInfo: SearchQueryItemInfo[] = FiltersUtilities.parseFiltersInfoList(f);

    filters.forEach((filter: SearchFilter) => {
      const setDataInfo: SetDataToFilterQueriesInfo = {
        parseFilterInfo,
        queriesInfo,
        filtersInfo
      };

      this.setParsedDataToFilterQueries(filter, setDataInfo);
    });

    return filters;
  }

  // ******************************** Private logic ********************************

  private static setParsedDataToFilterQueries(
    filter: SearchFilter,
    info: SetDataToFilterQueriesInfo
  ): void {
    const { queries, extraQueryInfo } = filter.data;
    const { parseFilterInfo } = info;

    queries.forEach((query: SearchFilterQuery) => {
      FiltersUtilities.setParsedDataToFilterQuery(query, info);
    });

    if (extraQueryInfo) {
      FiltersUtilities.setParsedExtraDataToFilterQueries(filter, info);
    }

    filter.setFacetByResponse(parseFilterInfo.facetResponse);
    filter.onDataSet();
  }

  private static setParsedDataToFilterQuery(
    query: SearchFilterQuery,
    info: SetDataToFilterQueriesInfo
  ): void {
    const { parseFilterInfo, queriesInfo, filtersInfo } = info;
    const itemInfo: SearchQueryItemInfo | SearchQueryItemInfo[] =
      query.data.type === 'filter'
        ? FiltersUtilities.getQueryItemInfoForFilter(query, filtersInfo)
        : FiltersUtilities.getQueryItemInfoForQuery(query, queriesInfo);

    if (Array.isArray(itemInfo)) {
      itemInfo.forEach((itemInfo: SearchQueryItemInfo) => {
        query.setDataFromSearchLine(itemInfo, parseFilterInfo);
      });
    } else if (itemInfo) {
      query.setDataFromSearchLine(itemInfo, parseFilterInfo);
    }
  }

  private static setParsedExtraDataToFilterQueries(
    filter: SearchFilter,
    info: SetDataToFilterQueriesInfo
  ): void {
    const { parseFilterInfo, queriesInfo } = info;
    const { extraQueryInfo } = filter.data;
    const itemInfo: SearchQueryItemInfo = FiltersUtilities.getExtraSearchQueryItemInfo(
      queriesInfo,
      extraQueryInfo
    );

    if (itemInfo) {
      const newQuery: SearchFilterQuery = filter.addExtraInputQuery();

      if (newQuery) {
        newQuery.setDataFromSearchLine(itemInfo, parseFilterInfo);
      }
    }
  }

  private static getExtraSearchQueryItemInfo(queriesInfo, extraQueryInfo): SearchQueryItemInfo {
    if (!queriesInfo) {
      return null;
    }

    return queriesInfo.find((item: SearchQueryItemInfo) => {
      return (
        item.queryParam === extraQueryInfo.queryParam &&
        (!extraQueryInfo.hasQueryDuplicates || item.index > 0)
      );
    });
  }

  private static getQueryItemInfoForFilter(
    query: SearchFilterQuery,
    filtersInfo: SearchQueryItemInfo[]
  ): SearchQueryItemInfo | SearchQueryItemInfo[] {
    if (!filtersInfo) {
      return null;
    }

    if (query.data.filterParamMultiple) {
      return filtersInfo.filter((filterItem: SearchQueryItemInfo) => {
        return query.data.filterParamMultiple.some((_: string) => _ === filterItem.filterParam);
      });
    }

    return filtersInfo.find(
      (filterItem: SearchQueryItemInfo) => query.data.filterParam === filterItem.filterParam
    );
  }

  private static getQueryItemInfoForQuery(
    query: SearchFilterQuery,
    queriesInfo: SearchQueryItemInfo[]
  ): SearchQueryItemInfo {
    if (!queriesInfo) {
      return null;
    }

    return queriesInfo.find((filterItem: SearchQueryItemInfo) => {
      if (filterItem.index > 0 && query.data.hasQueryDuplicates) {
        return false;
      }

      return query.data.queryParam === filterItem.queryParam;
    });
  }

  // ************************ Parse SearchQueryItemInfo list: ********************

  // parseQueryInfoList:

  private static parseQueryInfoList(q: string | string[]): SearchQueryItemInfo[] {
    let queryList: SearchQueryItemInfo[];

    if (Array.isArray(q)) {
      const baseList: SearchQueryItemInfo[] = q.map((filterItem: string) =>
        FiltersUtilities.getQueryParamItemInfo(filterItem, 'query')
      );
      queryList = FiltersUtilities.parseQueryListFromBase(baseList);
    } else {
      const item: SearchQueryItemInfo = FiltersUtilities.getQueryParamItemInfo(q, 'query');

      queryList = item ? [item] : null;
    }

    return queryList;
  }

  private static parseQueryListFromBase(baseList: SearchQueryItemInfo[]): SearchQueryItemInfo[] {
    return baseList.reduce((acc, item, index) => {
      const { searchText } = item;
      const excludedQueryOnly: boolean = SearchFilterQuery.isExcludedQueryOnly(searchText);

      if (excludedQueryOnly && index > 0 && acc.length) {
        const previousItem: SearchQueryItemInfo = acc[acc.length - 1];
        const isPreviousQueryIncludedOnly: boolean = SearchFilterQuery.isIncludedQueryOnly(
          previousItem.searchText
        );
        const previousHasSameParam: boolean = previousItem.queryParam === item.queryParam;

        if (isPreviousQueryIncludedOnly && previousHasSameParam) {
          const lastItemText: string = acc[acc.length - 1].searchText;
          const separator: string = SearchFilterQuery.parseSeparatorFromSearchText(searchText);
          const lastItemSeparator: string =
            SearchFilterQuery.parseSeparatorFromSearchText(lastItemText);
          const validSearchText: string = searchText.replace(separator, lastItemSeparator);

          acc[acc.length - 1].searchText = `${lastItemText}${lastItemSeparator}${validSearchText}`;
        } else {
          acc.push(item);
        }
      } else {
        acc.push(item);
      }

      return acc;
    }, []);
  }

  // parseFiltersInfoList:

  private static parseFiltersInfoList(f: string | string[]): SearchQueryItemInfo[] {
    let filtersInfo: SearchQueryItemInfo[];

    if (Array.isArray(f)) {
      filtersInfo = f.map((filterItem: string) =>
        FiltersUtilities.getQueryParamItemInfo(filterItem, 'filter')
      );
    } else {
      const info: SearchQueryItemInfo = FiltersUtilities.getQueryParamItemInfo(f, 'filter');
      filtersInfo = info ? [info] : null;
    }

    return filtersInfo;
  }

  // SearchQueryItemInfo handlers:

  private static getQueryParamItemInfo(
    query: string,
    queryType: SearchFilterQueryType
  ): SearchQueryItemInfo {
    let info: SearchQueryItemInfo;
    const separator: string = FiltersUtilities.getQueryParamSeparator(query);
    const queryMatchList: string[] = separator ? query.split(separator) : null;

    if (queryMatchList?.length) {
      const param: string = queryMatchList[0];
      const searchText: string = queryMatchList?.length > 1 ? queryMatchList[1] : '';
      const index: number = FiltersUtilities.getQueryParamItemInfoIndex(separator);

      info = { searchText, index };

      if (queryType === 'query') {
        info.queryParam = param;
      }
      if (queryType === 'filter') {
        info.filterParam = param;
      }
    }

    return info;
  }

  private static getQueryParamSeparator(query: string): string {
    const separatorMatchList: string[] = query?.match(searchQuerySeparator);

    return separatorMatchList?.length ? separatorMatchList[0] : null;
  }

  private static getQueryParamItemInfoIndex(separator: string): number {
    const indexString: string = separator.replace(searchItemQuerySeparator, '');

    if (indexString) {
      return parseInt(indexString);
    }

    return 0;
  }
}
